<template>
  <svg
    width="192"
    height="192"
    viewBox="0 0 192 192"
    xmlns="http://www.w3.org/2000/svg">
    <g fill="none" fill-rule="evenodd">
      <path
        d="M144.223 191.544H47.321C21.295 191.544 0 170.25 0 144.224V47.32C0 21.295 21.295 0 47.32 0h96.903c26.026 0 47.32 21.295 47.32 47.32v96.903c0 26.027-21.294 47.32-47.32 47.32"
        fill="#80BC5E" />
      <path
        d="M164.206 91.772c0 33.195-30.639 60.106-68.434 60.106-6.24 0-12.28-.736-18.022-2.113.019 4.008 1.022 8.384 3.603 12.978 1.69 3.011-1.745 6.259-4.713 4.495-7.019-4.177-15.709-12.137-21.687-27.217-16.756-10.953-27.615-28.488-27.615-48.25 0-33.194 30.639-60.105 68.434-60.105s68.434 26.911 68.434 60.106"
        fill="#FFF" />
      <path
        d="m73.64 88.236-.143.176a82.733 82.733 0 0 1-5.884 6.531l-5.087 5.072a16.946 16.946 0 0 0-1.379 1.553c-.297.38-.773.572-1.246.473l-8.353-1.752c-2.735-.568-3.744-3.933-1.766-5.91l4.121-4.122c4.348-4.347 12.585-5.539 19.073-4.017.887.208 1.237 1.288.664 1.996M99.627 136.001l-4.101 4.122c-1.977 1.957-5.363.968-5.931-1.766l-1.727-8.33a1.509 1.509 0 0 1 .541-1.47c.48-.394.947-.814 1.396-1.263l4.606-4.606a85.728 85.728 0 0 1 6.757-6.077l.481-.389c.875-.706 2.175-.222 2.397.88 1.303 6.473-.135 14.633-4.42 18.9M94.244 119.213c.18.053.235.273.103.407l-6.099 6.118c-6.667 6.646-17.498 6.646-24.144 0-3.372-3.372-5.148-7.861-4.997-12.636.144-4.53 2.245-8.778 5.449-11.982l5.62-5.62a.242.242 0 0 1 .407.107c.787 2.807 3.097 7.444 9.547 13.894 6.518 6.518 11.296 8.886 14.114 9.712"
        fill="#FFD345" />
      <path
        d="M114.541 88.388a9.13 9.13 0 0 1-6.545 2.719c-2.475 0-4.819-.956-6.563-2.72a9.207 9.207 0 0 1-2.72-6.544c0-2.494.976-4.82 2.72-6.564 1.744-1.762 4.088-2.719 6.563-2.719 2.476 0 4.801.957 6.564 2.72 1.744 1.744 2.7 4.069 2.7 6.563a9.13 9.13 0 0 1-2.719 6.545m11.313-24.737c-2.04-1.997-4.795-2.838-7.593-2.27-15.416 3.048-29.213 11.357-39.014 23.45l-5.742 7.088-.694.862-.4.483c.022.19.042.463.105.778.358 2.082 2.02 6.752 9.17 13.903 7.32 7.298 12.094 9.023 14.134 9.401.337.063.59.084.778.105l.484-.4.883-.694 6.646-5.405a66.706 66.706 0 0 0 23.619-39.771 9.962 9.962 0 0 0 .147-1.556c0-2.23-.883-4.354-2.523-5.974"
        fill="#80BC5E" />
      <path
        d="m71.501 118.615-.29-.289a2.713 2.713 0 0 1 0-3.832l6.564-6.563a2.707 2.707 0 0 1 3.83 0l.292.289a2.712 2.712 0 0 1 0 3.831l-6.564 6.564a2.71 2.71 0 0 1-3.832 0"
        fill="#FFF" />
      <path
        d="M135.072 58.66a3.8 3.8 0 1 1-7.6 0 3.8 3.8 0 0 1 7.6 0"
        fill="#80BC5E" />
    </g>
  </svg>
</template>

<script>
export default { name: "FormeLogo" };
</script>
