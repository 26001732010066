<template>
  <svg width="36" height="30" viewBox="0 0 36 30" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M4 30C3.44771 30 3 29.5523 3 29V10C3 9.44771 3.44772 9 4 9H8.66667C9.21895 9 9.66667 9.44772 9.66667 10V29C9.66667 29.5523 9.21895 30 8.66667 30H4ZM15.6667 30C15.1144 30 14.6667 29.5523 14.6667 29V1C14.6667 0.447716 15.1144 0 15.6667 0H20.3333C20.8856 0 21.3333 0.447715 21.3333 1V29C21.3333 29.5523 20.8856 30 20.3333 30H15.6667ZM27.3333 30C26.781 30 26.3333 29.5523 26.3333 29V19C26.3333 18.4477 26.781 18 27.3333 18H32C32.5523 18 33 18.4477 33 19V29C33 29.5523 32.5523 30 32 30H27.3333Z" fill="#80BC5E"/>
  </svg>

</template>

<script>
export default { name: "BarGraphIcon" };
</script>
