import vuetifyEN from "vuetify/src/locale/en";
import { $CommonType } from "@/plugins/locale/localeTypes";
import { ko } from "@/plugins/locale/ko";

const institution = {
  institution: "institution",
  institutionManagerName: "Manager",
  institutionEmail: "Email",
}

const $Common: $CommonType = {
  all: "All",
  cancel: "Cancel",
  center: "Center",
  confirmDelete: "Are you sure you want to delete this?",
  delete: "Delete",
  disease: "Disease",
  forme: "ForMe",
  greeting: "Hi There!",
  goodbye: "Goodbye!",
  guardian: "Guardian",
  headerEmail: "Email",
  headerLatestHistoryLogCTS: "Latest Used",

  headerNickName: "Name",
  headerPhone: "phone",
  headerSubscriptionEndTS: "Subscription End",
  headerSubscriptionStartTS: "Subscription Start",
  kid: "Kid",
  menuDashboard: "Dashboard",
  menuHistories: "Histories",
  menuKids: "Kids",
  modeDevops: "Devops View",
  none: "None",
  ok: "OK",
  or: "or",
  subtitle: "",
  taskLevel: "Applied Level",
  teacher: "Teacher",
  title: "",
  today: "Today",
  unknown: "Unknown",
  weekdaysLong: "Sunday Monday Tuesday Wednesday Thursday Friday Saturday",
  weekdaysShort: "Sun Mon Tue Wed Thu Fri Sat",
  yesterday: "Yesterday",
  userManagement: "User management",
  ...institution
};

export const en: typeof ko = {
  ...vuetifyEN,

  index: {
    ...$Common,
  },

  DashboardStatistics: {
    ...$Common,
    title: "Statistics",
    subtitle: "Daily statistics",
  },

  DashboardTrend: {
    ...$Common,
    title: "Usage Trend",
    achievedCount: "Achieved",
    achievedRate: "Achieved Rate(%)",
    finishedCount: "Finished",
    finishedRate: "Finished Rate(%)",
    scheduledCount: "Total",
  },

  DashboardUnscheduled: {
    ...$Common,
    title: "Schedule Issues",
    subtitle: "Unscheduled Kids",
    headerTherapist: "Therapist",
  },

  HistoryLogBot: $Common,

  HistoryLogChat: $Common,

  HistoryLogForme: $Common,

  HistoryLogKid: $Common,

  HistoryLogServer: $Common,

  HistoryLogTable: {
    ...$Common,
    headerCTS: "Date",
    headerFlow: "Flow",
    headerTalker: "Talker",
    headerListener: "Listener",
    headerConversation: "Conversation",
    headerVoice: "Voice",
  },

  HistoryLogTimeline: $Common,

  HistoryLogList: {
    ...$Common,
    modeTimeline: "Timeline View",
  },

  HistoryTable: {
    ...$Common,
    title: "All Histories",
    headerCTS: "Date",
    headerTask: "Task",
    headerScheduledStartTM: "Scheduled",
    headerNotificationTimeGap: "Alarmed",
    headerStartTS: "Started",
    headerEndTS: "Finished",
    headerAlarmedAgent: "Invoker",
    headerAchievedFlag: "Achievement",
    headerFinishedFlag: "Finished",
    headerFallbackCount: "Fallback Count",
    headerSilenceCount: "Silence Count",
  },

  KidInfoHistory: {
    ...$Common,
    title: "{0} | History Log",
    headerCTS: "Date",
    headerTask: "Task",
    headerScheduledStartTM: "Scheduled",
    headerNotificationTimeGap: "Alarmed",
    headerStartTS: "Started",
    headerEndTS: "Finished",
    headerAlarmedAgent: "Invoker",
    headerAchievedFlag: "Achievement",
    headerFinishedFlag: "Finished",
    headerFallbackCount: "Fallbacks",
    headerSilenceCount: "Silences",
  },

  KidScheduleCalendar: $Common,

  KidScheduleTable: {
    ...$Common,
    headerDisplayName: "Task",
    headerDuration: "Extra Duration",
    headerStartTM: "Scheduled",
    headerWeekdays: "Weekdays",
  },

  KidInfo: {
    ...$Common,
    historyLogs: "Conversation Logs",
  },

  KidTrend: {
    ...$Common,
    title: "Statistics",
    subtitle: "Daily Statistics",
    achievedCount: "Achieved",
    achievedRate: "Achieved Rate(%)",
    finishedCount: "Finished",
    finishedRate: "Finished Rate(%)",
    scheduledCount: "Scheduled",
  },

  KidTable: {
    ...$Common,
    title: "KidList List",
    headerCTS: "Registered",
    headerShortcut: "Shortcuts",
    historiesBtn: "Histories",
  },

  Alert: {
    ...$Common,
    unauthorized: "You Don't Have Permission To Access",
    forbidden: "Session Expired",
    apiError: "API Request Failed",
  },

  Anchor: $Common,

  App: $Common,

  AppBar: {
    ...$Common,
    title: "ForMe Admin",
  },

  Banner: $Common,

  Confirm: $Common,

  DatePicker: $Common,

  Loading: {
    ...$Common,
    loading: "Loading...",
    deleting: "Deleting...",
  },

  NavDrawer: {
    ...$Common,
    appPush: "App push",
    licenseManagement: "License Management",
    inquiryByInstitution : "",
    inquiryByKid : "",
    promiseManagement : "",
    userManagement : "",
  },

  Pagination: $Common,

  RangePicker: $Common,

  Refresh: $Common,

  Select: $Common,

  SelectBool: $Common,

  SelectTask: $Common,

  SignIn: {
    ...$Common,
    title: "ForMe Admin",
    staffOnly: "Staff Only",
    required: "Required",
    email: "Email",
    emailInvalid: "Invalid email address",
    password: "Password",
    signIn: "Sign in",
  },

  TextField: $Common,

  WithTooltip: $Common,

  KidHistory: $Common,

  KidHistoryList: $Common,

  KidSchedule: {
    ...$Common,
    title: "{0} | Schedule List",
    modeCalendar: "Calendar View",
  },

  Dashboard: $Common,

  HistoryList: $Common,

  Kid: $Common,

  KidList: $Common,
  MyInfo: $Common,
  SignUp: {
    ...$Common,
    title: "ForMe Admin",
    subtitle: "Sign up",
    required: "Required",
    email: "Email",
    emailInvalid: "Invalid email address",
    password: "Password",
    signUp: "Sign up",
    name: "Name",
    birthDt: "BirthDay",
    gender: "Gender",
    school: "School",
    classAndGrade: "Class And Grade",
  },

  UserTable: $Common,
  AppPush: {
    ...$Common,
    appPush: "App push",
    pushMessage: "Push Message",
    pushTitle: "Push Title",
  },
  LicenseTable: {
    ...$Common,
    title: "License Management",
    registeredFlag: "Registration Status",
    parentName: "Parental Name",
    kidName: "Child's name",
    license: "license",
    startDt: "start date",
    endDt: "end date",
    parentInfo: "Parental personal information",
    unregistered: "unregistered",
    registration: "registration",
    validNum: "the number of valid licenses",
  },
  OrganizationInfo: {
    ...$Common,
    validNum: "the number of valid licenses",
  },
  EditParentInfoDialog: {
    ...$Common,
    license: "license",
    parentName: "Parental Name",
    kidName: "Child's name",
  },

  OrganizationTrend: {
    ...$Common,
    title: "Statistics",
    subtitle: "Daily Statistics",
    achievedCount: "Achieved",
    achievedRate: "Achieved Rate(%)",
    finishedCount: "Finished",
    finishedRate: "Finished Rate(%)",
    scheduledCount: "Scheduled",
  },
};
