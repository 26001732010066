import vuetifyKO from "vuetify/src/locale/ko";
import { $CommonType } from "@/plugins/locale/localeTypes";

const institution = {
  institution: "기관",
  institutionManagerName: "대표 관리자",
  institutionEmail: "이메일",
}


const $Common: $CommonType = {
  all: "전체",
  cancel: "취소",
  center: "학교",
  confirmDelete: "삭제하면 복구할 수 없습니다. 계속하시겠습니까?",
  delete: "삭제",
  disease: "질환",
  forme: "뽀미",
  greeting: "안녕하세요!",
  goodbye: "안녕히 가세요!",
  guardian: "보호자",
  headerEmail: "이메일",
  headerLatestHistoryLogCTS: "마지막 사용",

  headerNickName: "이름",
  headerPhone: "전화번호",
  headerSubscriptionEndTS: "사용 종료",
  headerSubscriptionStartTS: "사용 시작",
  kid: "어린이",
  menuDashboard: "메인",
  modeDevops: "개발자 모드",
  none: "없음",
  ok: "확인",
  or: "또는",
  subtitle: "",
  taskLevel: "적용 레벨",
  teacher: "담임 선생님",
  title: "",
  today: "오늘",
  unknown: "모름",
  weekdaysLong: "일요일 월요일 화요일 수요일 목요일 금요일 토요일",
  weekdaysShort: "일 월 화 수 목 금 토",
  yesterday: "어제",
  userManagement: "사용자 관리",
  ...institution
};

export const ko = {
  ...vuetifyKO,

  index: {
    ...$Common,
  },

  DashboardStatistics: {
    ...$Common,
    title: "전체 수행률 확인",
    subtitle: "일별 약속 수행 정도에 따른 아동의 분포를 확인할 수 있음",
  },

  DashboardTrend: {
    ...$Common,
    title: "사용량 추이",
    achievedCount: "진행(명)",
    achievedRate: "진행률(%)",
    finishedCount: "완료(명)",
    finishedRate: "완료율(%)",
    scheduledCount: "전체(명)",
  },

  DashboardUnscheduled: {
    ...$Common,
    title: "약속 선택 이슈",
    subtitle: "약속을 골라야 하는데, 약속 선택을 하지 않은 아동만 보여줌",
    headerTherapist: "학교",
  },

  HistoryLogBot: $Common,

  HistoryLogChat: $Common,

  HistoryLogForme: $Common,

  HistoryLogKid: $Common,

  HistoryLogServer: $Common,

  HistoryLogTable: {
    ...$Common,
    headerCTS: "날짜",
    headerFlow: "플로우",
    headerTalker: "화자",
    headerListener: "청자",
    headerConversation: "발화",
    headerVoice: "음성 파일",
  },

  HistoryLogTimeline: $Common,

  HistoryLogList: {
    ...$Common,
    modeTimeline: "대화 형식으로 보기",
  },

  HistoryTable: {
    ...$Common,
    title: "전체 어린이 약속 진행 확인",
    headerCTS: "날짜",
    headerTask: "약속 이름",
    headerScheduledStartTM: "약속 시간",
    headerNotificationTimeGap: "알람 시간",
    headerStartTS: "시작 시간",
    headerEndTS: "완료 시간",
    headerAlarmedAgent: "시작 주체",
    headerAchievedFlag: "아이 첫 발화",
    headerFinishedFlag: "완료",
    headerFallbackCount: "Fallback 횟수",
    headerSilenceCount: "침묵 횟수",
  },


  KidInfoHistory: {
    ...$Common,
    title: "{0} | 세부 발화 조회",
    headerCTS: "날짜",
    headerTask: "약속 이름",
    headerScheduledStartTM: "약속 시간",
    headerNotificationTimeGap: "알람 시간",
    headerStartTS: "시작 시간",
    headerEndTS: "완료 시간",
    headerAlarmedAgent: "시작 주체",
    headerAchievedFlag: "아이 첫 발화",
    headerFinishedFlag: "완료",
    headerFallbackCount: "Fallback 횟수",
    headerSilenceCount: "침묵 횟수",
  },

  KidScheduleCalendar: $Common,

  KidScheduleTable: {
    ...$Common,
    headerDisplayName: "약속 이름",
    headerDuration: "추가 소요 시간",
    headerStartTM: "시작 시간",
    headerWeekdays: "요일",
  },

  KidInfo: {
    ...$Common,
    historyLogs: "세부 발화 조회",
  },

  KidTrend: {
    ...$Common,
    title: "진행률",
    subtitle: "당일 약속 진행 횟수",
    achievedCount: "진행",
    achievedRate: "진행률(%)",
    finishedCount: "완료",
    finishedRate: "완료율(%)",
    scheduledCount: "약속",
  },

  KidTable: {
    ...$Common,
    title: "전체 어린이 리스트",
    headerCTS: "가입",
    headerShortcut: "바로 가기",
    historiesBtn: "약속 진행",
  },

  Alert: {
    ...$Common,
    unauthorized: "권한이 없습니다",
    forbidden: "세션이 만료되었습니다",
    apiError: "API 오류가 발생했습니다",
  },

  Anchor: $Common,

  App: $Common,

  AppBar: {
    ...$Common,
    title: "뽀미 어드민",
  },

  Banner: $Common,

  Confirm: $Common,

  DatePicker: $Common,

  Loading: {
    ...$Common,
    loading: "로딩 중...",
    deleting: "삭제 중...",
  },

  NavDrawer: {
    ...$Common,
    appPush: "앱푸시",
    inquiryByInstitution : "기관별 조회",
    inquiryByKid : "어린이별 조회",
    promiseManagement : "약속 진행 관리",
    userManagement : "사용자 관리",
    licenseManagement: "이용권 관리",
  },

  Pagination: $Common,

  RangePicker: $Common,

  Refresh: $Common,

  Select: $Common,

  SelectBool: $Common,

  SelectTask: $Common,

  SignIn: {
    ...$Common,
    title: "뽀미 어드민",
    staffOnly: "임직원 전용",
    required: "필수 입력 값입니다",
    email: "이메일",
    emailInvalid: "이메일 형식이 아닙니다",
    password: "비밀번호",
    signIn: "로그인",
  },

  TextField: $Common,

  WithTooltip: $Common,

  KidHistory: $Common,

  KidHistoryList: $Common,

  KidSchedule: {
    ...$Common,
    title: "{0} | 선택 약속 확인",
    modeCalendar: "달력으로 보기",
  },

  Dashboard: $Common,

  HistoryList: $Common,

  Kid: $Common,

  KidList: $Common,
  MyInfo: $Common,
  SignUp: {
    ...$Common,
    title: "뽀미 어드민",
    subtitle: "회원가입",
    required: "필수 입력 값입니다",
    email: "이메일",
    emailInvalid: "이메일 형식이 아닙니다",
    password: "비밀번호",
    signUp: "회원가입",
    name: "이름",
    birthDt: "생년월일",
    gender: "성별",
    school: "학교",
    classAndGrade: "학년 반",
  },

  UserTable: $Common,
  AppPush: {
    ...$Common,
    appPush: "앱푸시",
    pushMessage: "푸시 메시지",
    pushTitle: "푸시 타이틀",
  },

  LicenseTable: {
    ...$Common,
    title: "이용권 관리",
    registeredFlag: "등록 여부",
    parentName: "보호자 이름",
    kidName: "아이 이름",
    license: "이용권 코드",
    startDt: "사용 시작일",
    endDt: "사용 종료일",
    parentInfo: "보호자 인적사항",
    unregistered: "미등록",
    registration: "등록",
    validNum: "이용권 유효 갯수",
  },
  OrganizationInfo: {
    ...$Common,
    validNum: "이용권 유효 갯수",
  },
  EditParentInfoDialog: {
    ...$Common,
    license: "이용권 코드",
    parentName: "보호자 이름",
    kidName: "아이 이름",
  },
  OrganizationTrend: {
    ...$Common,
    title: "사용량 추이",
    achievedCount: "진행(명)",
    achievedRate: "진행률(%)",
    finishedCount: "완료(명)",
    finishedRate: "완료율(%)",
    scheduledCount: "전체(명)",
  },

};
