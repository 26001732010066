import { GetterTree } from "vuex";
import {
  AlertDialogInterface,
  ConfirmDialogInterface,
   RootState,
  SettingInterface,
  state,
} from "@/store/state";
import { AdminResponse, OrganizationResponse } from "@/generated/adminapi";
import { KidInterface } from "@/views/KidList/Kid.vue";


export const getters: GetterTree<typeof state, RootState> = {
  setting: (state: RootState): SettingInterface => state.setting,
  admin: (state: RootState): AdminResponse => state.admin,
  alertDialog: (state: RootState): AlertDialogInterface => state.alertDialog,
  confirmDialog: (state: RootState): ConfirmDialogInterface =>
    state.confirmDialog,
  recentKid: (state: RootState): KidInterface =>
    state.recentKid,
  loading: (state: RootState): boolean => state.loading,
  organizationList: (state: RootState): OrganizationResponse[] =>
    state.organizationList,
  firstGroup: (state: RootState): string[] =>
    state.firstGroup,
};

export type Getters = typeof getters;
