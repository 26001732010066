import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import Dashboard from "@/views/Dashboard.vue";
import { HaiiAuth } from "@/plugins/haii/HaiiExAuth";
import store from "@/store";
import RouteNames from "@/const/route";
import { Route } from "vue-router/types/router";


Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "",
    component: () =>
      import(
        /* webpackChunkName: "layouts-default-index" */ "@/layouts/Index.vue"
        ),
    children: [
      {
        path: "",
        name: RouteNames.MAIN,
        component: Dashboard
      },
      {
        path: "organizations",
        name: RouteNames.ORGANIZATION_LIST,
        component: () =>
          import(/* webpackChunkName: "views-OrganizationList" */ "@/views/OrganizationList.vue"),
        meta: {}
      },
      {
        path: "organizations/:organizationUUID/:adminDisplayName/:model/histories",
        name: RouteNames.ORGANIZATION_HISTORY_LIST,
        props: true,
        component: () =>
          import(/* webpackChunkName: "views-OrganizationHistoryList" */ "@/views/OrganizationList/Organization/OrganizationHistoryList.vue"),
        meta: {}
      },
      {
        path: "organizations/:organizationUUID/:adminDisplayName/:model/choosePromises",
        name: RouteNames.ORGANIZATION_CHOOSE_PROMISE_LIST,
        props: true,
        component: () =>
          import(/* webpackChunkName: "views-OrganizationChoosePromiseList" */ "@/views/OrganizationList/Organization/OrganizationChoosePromiseList.vue"),
        meta: {}
      },
      {
        path: "organizations/:organizationUUID/:adminDisplayName/:model/dayDiarys",
        name: RouteNames.ORGANIZATION_DAY_DAIRY_LIST,
        props: true,
        component: () =>
          import(/* webpackChunkName: "views-OrganizationDayDiaryList" */ "@/views/OrganizationList/Organization/OrganizationDayDiaryList.vue"),
        meta: {}
      },
      {
        path: "organizations/:organizationUUID/:adminDisplayName/:model/surveys",
        name: RouteNames.ORGANIZATION_SURVEY_LIST,
        props: true,
        component: () =>
          import(/* webpackChunkName: "views-OrganizationSurveyList" */ "@/views/OrganizationList/Organization/OrganizationSurveyList.vue"),
        meta: {}
      },
      {
        path: "organizations/:organizationUUID/:adminDisplayName/:model/help-forme",
        name: RouteNames.ORGANIZATION_HELP_FORME_LIST,
        props: true,
        component: () =>
          import(/* webpackChunkName: "views-OrganizationHelpFormeList" */ "@/views/OrganizationList/Organization/OrganizationHelpFormeList.vue"),
        meta: {}
      },
      {
        path: "organizations/:organizationUUID/:adminDisplayName/:model",
        name: RouteNames.ORGANIZATION,
        props: true,
        component: () =>
          import(/* webpackChunkName: "views-OrganizationList" */ "@/views/OrganizationList/Organization.vue"),
        meta: {}
      },

      {
        path: "kids",
        name: RouteNames.KID_LIST,
        component: () =>
          import(/* webpackChunkName: "views-KidList" */ "@/views/KidList.vue"),
        meta: {}
      },
      {
        path: "/kids",
        component: () =>
          import(
            /* webpackChunkName: "views-Kid" */ "@/views/KidList/Kid.vue"
            ),
        props: true,
        meta: {},
        children: [
          {
            path: ":kidID",
            name: RouteNames.KID,
            component: () =>
              import(
                /* webpackChunkName: "views-KidInfo" */ "@/views/KidList/Kid/KidInfo.vue"
                ),
            props: true
          },
          {
            path: ":kidID/histories",
            name: RouteNames.KID_HISTORY_LIST,
            component: () =>
              import(
                /* webpackChunkName: "views-KidHistoryList" */ "@/views/KidList/Kid/KidHistoryList.vue"
                ),
            props: true,
            meta: {}
          },
          {
            path: ":kidID/choose-promises",
            name: RouteNames.KID_CHOOSE_PROMISE_LIST,
            component: () =>
              import(
                /* webpackChunkName: "views-KidChoosePromiseList" */ "@/views/KidList/Kid/KidChoosePromiseList.vue"
                ),
            props: true,
            meta: {}
          },
          {
            path: ":kidID/day-diary",
            name: RouteNames.KID_DAY_DAIRY_LIST,
            component: () =>
              import(
                /* webpackChunkName: "views-KidDayDiaryList" */ "@/views/KidList/Kid/KidDayDiaryList.vue"
                ),
            props: true,
            meta: {}
          },
          {
            path: ":kidID/surveys",
            name: RouteNames.KID_SURVEY_LIST,
            component: () =>
              import(
                /* webpackChunkName: "views-KidSurvey" */ "@/views/KidList/Kid/KidSurvey.vue"
                ),
            props: true,
            meta: {}
          },
          {
            path: ":kidID/help-forme",
            name: RouteNames.KID_HELP_FORME_LIST,
            component: () =>
              import(
                /* webpackChunkName: "views-KidHelpFormeList" */ "@/views/KidList/Kid/KidHelpFormeList.vue"
                ),
            props: true,
            meta: {}
          }
        ]
      },
      {
        path: "/histories",
        name: RouteNames.PROMISE_HISTORY_LIST,
        component: () =>
          import(
            /* webpackChunkName: "views-PromiseHistoryList" */ "@/views/PromiseHistoryList.vue"
            ),
        meta: {}
      },
      {
        path: ":kidID/histories/:historyID",
        name: RouteNames.KID_HISTORY,
        component: () =>
          import(
            /* webpackChunkName: "views-KidHistory" */ "@/views/PromiseHistoryList/PromiseHistory.vue"
            ),
        props: true,
        meta: {}
      },
      {
        path: "/users",
        name: RouteNames.USER_LIST,
        component: () =>
          import(
            /* webpackChunkName: "views-UsersList" */ "@/views/UsersList.vue"
            ),
        meta: {}
      },
      {
        path: "/users/invite",
        name: RouteNames.USER_INVITE,
        component: () =>
          import(
            /* webpackChunkName: "views-UserInvite" */ "@/views/UsersList/UserInvite.vue"
            ),
        meta: {}
      },
      {
        path: "/apppush",
        name: RouteNames.APP_PUSH,
        component: () =>
          import(/* webpackChunkName: "views-AppPush" */ "@/views/AppPush.vue"),
        meta: {}
      },
      {
        path: "/license-management",
        name: RouteNames.LICENSE_MANAGEMENT,
        component: () =>
          import(
            /* webpackChunkName: "views-LicenseManagement" */ "@/views/LicenseManagement.vue"
            ),
        meta: {}
      }
    ]
  },
  {
    path: "*",
    redirect: "/"
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = async function push(location): Promise<Route> {
  try {
    return await originalPush.bind(this)(location);
  } catch (e:any) {
    if (e?.name === 'NavigationDuplicated') {
      return  e
    } else {
      console.warn(e)
      throw e
    }
  }
};

const checkUpdate = () => {
  if ("serviceWorker" in navigator) {
    navigator.serviceWorker.getRegistrations().then((regs) => {
      const reg = regs.find((reg) => {
        if (!reg.active) return;
        if (reg.active.scriptURL.indexOf("service-worker.js") != -1)
          return true;
      });
      if (!reg) return;
      reg.update();
    });
  }
};

router.beforeEach(async (to, from, next) => {
  checkUpdate();
  await HaiiAuth.Instance.manageToken(true);

  const userRole = store.getters.admin.roleLevel;
  const ableRoleList: number[] = to.meta?.forbidden;

  if (!ableRoleList) {
    next();
    return;
  }

  if (!Array.isArray(ableRoleList)) {
    next();
    return;
  }

  if (ableRoleList.find((role) => role == userRole)) {
    alert("접근 권한이 없습니다.");

    router.back();
  } else {
    next();
  }
});

export default router;
