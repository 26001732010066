<template>
  <svg fill="none" height="16" viewBox="0 0 14 16" width="14" xmlns="http://www.w3.org/2000/svg">
    <path d="M13.1309 14.7056L13.1309 1.29617C13.1309 0.273631 12.0048 -0.347658 11.1376 0.208914L0.601541 6.91365C0.417082 7.03039 0.265143 7.19188 0.159859 7.38311C0.0545747 7.57433 -0.000633476 7.78908 -0.000633495 8.00738C-0.000633514 8.22567 0.0545747 8.44042 0.159859 8.63165C0.265143 8.82287 0.417081 8.98437 0.601541 9.1011L11.1376 15.7929C11.3328 15.9192 11.5585 15.9904 11.7908 15.9991C12.0232 16.0078 12.2535 15.9536 12.4577 15.8423C12.6618 15.7309 12.8321 15.5666 12.9505 15.3665C13.069 15.1665 13.1313 14.9381 13.1309 14.7056Z" fill="#808080"/>
  </svg>

</template>

<script>
export default { name: "BeforeIcon" };
</script>
