import Vue from "vue";
import App from "@/App.vue";
import forme from "@/plugins/haii";
import vuetify from "@/plugins/vuetify";
import router from "@/router";
import store from "@/store";
import "@/registerServiceWorker";
import "@/styles/default.css";
import emitter from "@/plugins/haii/mitt";
import HaiiExAuth, { HaiiAuth } from "@/plugins/haii/HaiiExAuth";
import { sentryInit } from "@/plugins/sentry";
import "@/plugins/logger"

HaiiAuth.Instance.init({
  serviceName: "forme",
  isProduction: process.env.VUE_APP_ENV === "production",
});

Vue.use(HaiiExAuth);
Vue.use(forme);
Vue.use(emitter);
sentryInit()

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
