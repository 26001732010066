<template>
  <svg fill="none" height="30" viewBox="0 0 36 30" width="36" xmlns="http://www.w3.org/2000/svg">
    <path clip-rule="evenodd" d="M6.24884 1.64186C6.24884 1.20641 6.0777 0.788796 5.77306 0.480889C5.46842 0.172981 5.05525 0 4.62442 0C4.1936 0 3.78042 0.172981 3.47578 0.480889C3.17114 0.788796 3 1.20641 3 1.64186V28.3581C3 29.2644 3.72774 30 4.62442 30H31.057C31.4878 30 31.901 29.827 32.2057 29.5191C32.5103 29.2112 32.6814 28.7936 32.6814 28.3581C32.6814 27.9227 32.5103 27.5051 32.2057 27.1972C31.901 26.8893 31.4878 26.7163 31.057 26.7163H6.24668L6.24884 1.64186ZM24.9773 4.99781C24.741 4.82746 24.4732 4.70687 24.1898 4.6432C23.9065 4.57953 23.6133 4.57408 23.3279 4.62718C23.0424 4.68027 22.7704 4.79083 22.528 4.95228C22.2856 5.11372 22.0778 5.32277 21.9169 5.56699L15.2503 15.6721L12.0361 12.4234C11.8349 12.2198 11.596 12.0584 11.333 11.9482C11.07 11.8379 10.7881 11.7812 10.5034 11.7811C10.2187 11.781 9.93682 11.8375 9.67376 11.9476C9.41071 12.0576 9.17167 12.2189 8.97029 12.4223C8.76891 12.6257 8.60915 12.8672 8.50011 13.133C8.39107 13.3988 8.3349 13.6837 8.3348 13.9714C8.3347 14.2592 8.39067 14.5441 8.49952 14.81C8.60838 15.0759 8.76797 15.3175 8.96921 15.521L14.0526 20.6567C14.2791 20.8861 14.5532 21.0618 14.8551 21.1712C15.157 21.2805 15.4791 21.3206 15.7983 21.2888C16.1174 21.257 16.4256 21.1539 16.7005 20.9871C16.9754 20.8202 17.2102 20.5937 17.388 20.324L24.2778 9.87522L29.5755 13.6996C30.0431 14.037 30.6241 14.1727 31.1908 14.0771C31.7574 13.9814 32.2632 13.6622 32.597 13.1896C32.9307 12.717 33.065 12.1297 32.9704 11.557C32.8758 10.9843 32.5599 10.4731 32.0923 10.1357L24.9773 4.99781Z" fill="#80BC5E" fill-rule="evenodd"/>
  </svg>

</template>

<script>
export default { name: "LinearGraphIcon" };
</script>
