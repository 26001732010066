/**
 * ForMe Server
 * 2.0.0
 * DO NOT MODIFY - This file has been generated using oazapfts.
 * See https://www.npmjs.com/package/oazapfts
 */
import * as Oazapfts from "oazapfts/lib/runtime";
import * as QS from "oazapfts/lib/runtime/query";
export const defaults: Oazapfts.RequestOpts = {
    baseUrl: "http://localhost:8080/api",
};
const oazapfts = Oazapfts.runtime(defaults);
export const servers = {
    localDevelopment: "http://localhost:8080/api",
    officeDevelopmentLan: "http://10.20.50.105:8080/api",
    officeDevelopmentTailscale: "http://vpn-friday.haiidev.co.kr:8080/api",
    gcpDevelopment: "https://forme.haiidev.co.kr/api",
    production: "https://forme.haii.io/api",
    localApiGateway: "http://localhost:8880/forme",
    gcpDevApiGateway: "https://api.haiidev.co.kr/forme",
    gcpProdApiGateway: "https://api.haii.io/forme",
    officeDevelopmentTailscaleApiGateway: "http://vpn-friday.haiidev.co.kr:8880/forme"
};
export type EntityResponse = {
    UUID: string;
    CTS?: number;
    MTS?: number;
};
export type SchedulePlanResponse = EntityResponse & {
    startDate: string;
    period: number;
    endDate: string;
};
export type OrganizationResponse = EntityResponse & {
    displayName: string;
    model: string;
    firstGroup: string;
    region: string;
    address?: string;
};
export type LicenseDetail = EntityResponse & {
    lCode: string;
    sCode: string;
    version?: number;
    issuer?: string;
    model?: string;
    product?: number;
    item?: number;
    issued?: number;
    expire?: number;
    vCode?: string;
    iCode?: string;
    registered_flag?: boolean;
    delivery_flag?: boolean;
    appendix?: string;
    planStartDT?: string;
    planEndDT?: string;
};
export type AdminResponse = EntityResponse & {
    displayName: string;
    email: string;
    phone?: string;
    roleLevel?: number;
    organizationName?: string;
    organization?: OrganizationResponse;
    appendix?: string;
    license?: LicenseDetail;
};
export type EntityListResponse = {
    total?: number;
};
export type BillingResponse = {
    lCode: string;
    activeTS: number;
    expireTS: number;
};
export type BillingListResponse = EntityListResponse & {
    billing: BillingResponse[];
};
export type AssessmentResult = {
    assessmentDT: string;
    taskLevel: number;
};
export type UserResponse = EntityResponse & {
    exID: string;
    parentExID: string;
    nickName: string;
    fullName: string;
    taskLevels: number[];
    sttDisplayFlag: boolean;
    demoFlag: boolean;
    cyclePeriod: number;
    affiliates: number[];
    prescribedFlag: boolean;
    requiredTaskUUIDs: string[];
    appendix: string;
    licenseFlag?: boolean;
    planStartDT?: string;
    planEndDT?: string;
    latestHistoryLogCTS?: number;
    enabledFlag: boolean;
    assessmentFlag?: boolean;
    biomarkerFlag?: boolean;
    biomarkerCount?: number;
    biomarkerFinishedDTs?: string[];
    cntFlag?: boolean;
    sCode?: string;
    grade?: number;
    "class": string;
    birth: string;
    region: string;
    firstGroup: string;
    organizationName: string;
    licenseCount: number;
    plan?: SchedulePlanResponse;
    admin?: AdminResponse;
    billingList?: BillingListResponse;
    assessmentResults?: AssessmentResult[];
};
export type GenericResponse = {
    code: number;
    message: string | object;
};
export type UserRequest = {
    nickName?: string;
    demoFlag?: boolean;
    affiliates?: number[];
    lastName?: string;
    firstName?: string;
    birth?: string;
    region?: string;
    firstGroup?: string;
    organizationName?: string;
    grade?: number;
    "class"?: string;
    gender?: string;
    contactNumber?: string;
    appuserUUID?: string;
    model?: string;
    planPeriod?: number;
    entryUUID?: string;
};
export type UserListResponse = EntityListResponse & {
    users: UserResponse[];
};
export type TaskResponse = EntityResponse & {
    displayName: string;
    enabledFlag?: boolean;
    icon: string;
    defaultStartTM?: string;
    customizableFlag?: boolean;
    section?: number;
    essentialFlag?: boolean;
    drugFlag?: boolean;
    effectColors?: number[];
    flowPrefix?: string;
    requiredFlag?: boolean;
    details: string[];
    performableWeek?: number;
    alwaysPossibleFlag?: boolean;
};
export type TaskListResponse = EntityListResponse & {
    tasks: TaskResponse[];
};
export type ScheduleTimetableEvent = {
    task: TaskResponse;
    startTM: string;
    duration: number;
};
export type ScheduleTimetableWeekday = ScheduleTimetableEvent[];
export type ScheduleTimetableRequest = {
    startDate: string;
    "default": ScheduleTimetableEvent[];
    weekdays: ScheduleTimetableWeekday[];
};
export type ScheduleTimetableResponse = {
    cyclePeriod: number;
    startDate: string;
    endDate: string;
    "default": ScheduleTimetableEvent[];
    weekdays: ScheduleTimetableWeekday[];
};
export type ScheduleCycleResponse = EntityResponse & {
    startDate: string;
    period: number;
    endDate: string;
    assessmentFlag?: boolean;
};
export type UserToDoResponseNotification = {
    mode: string;
    taskUUID: string;
    startTS: number;
    expires: number;
    title?: string;
    sound?: string;
};
export type UserToDoResponse = {
    nextSyncTS: number;
    notifications: UserToDoResponseNotification[];
};
export type CycleAndTodoResponse = {
    cycle: ScheduleCycleResponse;
    todo: UserToDoResponse;
};
export type DashboardNotiResponse = {
    enabledFlag: boolean;
    cycleAndTodos: CycleAndTodoResponse[];
};
export type UserTodoFinishRequest = {
    taskUUID: string;
    emotion: number;
};
export type UserTodoFinishResponse = {
    totalStars: number;
};
export type DashboardResponseSchedule = EntityResponse & {
    taskUUID: string;
    displayName: string;
    icon: string;
    startTM: string;
    achievement: string;
    possibleBeforeTM?: string;
    delayFlag: boolean;
    finishedFlag?: boolean;
    praiseFlag?: boolean;
    notiTS?: number;
    step2FinishedFlag?: boolean;
    alwaysPossibleFlag: boolean;
    step: number;
    cycleEmotions?: number[];
};
export type SubscriptionResponse = EntityResponse & {
    period: number;
    startDate?: string;
    endDate?: string;
};
export type CntFinishedFlag = {
    magicBallFinishedFlag: boolean;
    magicPotionFinishedFlag: boolean;
    magicGiftFinishedFlag: boolean;
};
export type CntLevel = {
    magicBallLevel?: number;
    magicPotionForwardLevel?: number;
    magicPotionBackwardLevel?: number;
    magicGiftLevel?: number;
};
export type CntResponse = EntityResponse & CntFinishedFlag & CntLevel;
export type DashboardResponse = {
    enabledFlag: boolean;
    schedules: DashboardResponseSchedule[];
    plan?: SchedulePlanResponse;
    cycle?: ScheduleCycleResponse;
    subscription?: SubscriptionResponse;
    todo?: UserToDoResponse;
    cnt?: CntResponse;
};
export type JournalResponse = {
    date: string;
    tasks: number;
    stars: number;
    iconURL: string;
    cardURL: string;
    emotion?: string;
};
export type JournalGroupResponse = {
    journals: JournalResponse[];
};
export type JournalListResponse = {
    totalStars: number;
    totalGroups: number;
    groups: JournalGroupResponse[];
};
export type MessageCheckResponse = {
    messageFlag: boolean;
};
export type MessageStatusRequest = {
    messageUUID: string;
};
export type MessageResponse = EntityResponse & {
    messageURL: string;
    readFlag?: boolean;
};
export type MessageListResponse = EntityListResponse & {
    messages: MessageResponse[];
    sentCount: number;
};
export type MessageCountResponse = {
    messageCTS: number;
};
export type MessageCountListResponse = EntityListResponse & {
    messages: MessageCountResponse[];
};
export type MessageRequest = {
    file: Blob;
    query?: string;
    appuserUUID?: string;
    historyUUID?: string;
    emotion?: string;
};
export type OrganizationListResponse = EntityListResponse & {
    organization: OrganizationResponse[];
};
export type KinderInfoResponse = {
    addr: string;
    kindername: string;
    officeedu: string;
};
export type ChildSchoolInfoListResponse = {
    status: string;
    kinderInfo: KinderInfoResponse[];
};
export type PastDashboardResponseSchedule = {
    startDT: string;
    endDT: string;
    displayName: string;
    icon: string;
    taskPCT: number;
    finishedCNT?: number;
    taskCNT?: number;
    hRank?: number;
    lRank?: number;
    tag?: string;
};
export type PastDashboardResponse = {
    taskRates: PastDashboardResponseSchedule[];
    taskList: PastDashboardResponseSchedule[];
};
export type WordCloudResponse = EntityResponse & {
    name?: string;
    value?: number;
};
export type WordCloudListResponse = EntityListResponse & {
    words: WordCloudResponse[];
};
export type AssessmentResponse = {
    score: number;
    level: number;
};
export type AssessmentListResponse = EntityListResponse & {
    assessment: AssessmentResponse[];
};
export type AssessmentRequest = {
    no: number;
    question: string;
    answer: string;
    score: number;
    negativeFlag: boolean;
};
export type AssessmentListRequest = {
    assessmentList?: AssessmentRequest[];
};
export type BiomarkerRequest = {
    files: Blob[];
    collectType: string;
};
export type SurveyRequest = {
    no: number;
    question: string;
    answer: string;
    score: number;
    category?: string;
};
export type SurveyListRequest = {
    surveyList: SurveyRequest[];
    appuserUUID: string;
};
export type CntRequest = {
    magicBallLevel?: number;
    magicPotionForwardLevel?: number;
    magicPotionBackwardLevel?: number;
    magicGiftLevel?: number;
};
export type PastCntResponse = {
    yesterday: CntLevel;
    best: CntLevel;
};
export type UserVerifyResponse = {
    entryUUID: string;
};
export type EvaluationResultResponse = {
    component: string;
    score: number;
};
export type EvaluationResponse = {
    date: string;
    selfControl: EvaluationResultResponse[];
    mood: EvaluationResultResponse[];
    metaCognitive: EvaluationResultResponse[];
};
export type StateResponse = {
    date: string;
    finishedFlag: boolean;
    gloom?: number;
    anxiety?: number;
    stress?: number;
};
export type SchoolReadinessResponse = {
    date: string;
    score: number;
};
export type StatusReportListResponse = EntityListResponse & {
    evaluation: EvaluationResponse[];
    state: StateResponse[];
    schoolReadiness: SchoolReadinessResponse[];
};
export type AppVersionResponse = {
    platform: string;
    version: string;
    forceUpdateFlag: boolean;
};
export type LicenseRegisterRequest = {
    sCode: string;
    appuserUUID: string;
};
export type LicenseVerifyRequest = {
    sCode: string;
    model: string;
    kidName?: string;
    birthDT?: string;
    gender?: string;
    contactNumber?: string;
};
export type ChatbotRequestInitiate = {
    taskUUID: string;
    mode: string;
};
export type ChatbotResponseExtra = {
    step?: number;
    icon?: string;
    effect?: string;
    effectOnce?: boolean;
    effectColor?: number[];
    emotion?: string;
    emotionOnce?: boolean;
};
export type ChatbotResponseJobChatData = {
    text: string;
    noCache: boolean;
    streamURL: string;
};
export type ChatbotResponseJobChat = {
    jobType: string;
    extra?: ChatbotResponseExtra;
    data: ChatbotResponseJobChatData;
};
export type ChatbotResponseJobSttStartData = {
    timeout: number;
    displayText: boolean;
    enableNextButton: boolean;
};
export type ChatbotResponseJobSttStart = {
    jobType: string;
    extra?: ChatbotResponseExtra;
    data: ChatbotResponseJobSttStartData;
};
export type ChatbotResponseJobSttChangeData = {
    stt: string;
};
export type ChatbotResponseJobSttChange = {
    jobType: string;
    extra?: ChatbotResponseExtra;
    data: ChatbotResponseJobSttChangeData;
};
export type ChatbotResponseJobWaitData = {
    duration: number;
};
export type ChatbotResponseJobWait = {
    jobType: string;
    extra?: ChatbotResponseExtra;
    data: ChatbotResponseJobWaitData;
};
export type ChatbotResponseJobTimerData = {
    maximum: number;
    minimum: number;
    sound: string;
    soundRepeat: boolean;
};
export type ChatbotResponseJobTimer = {
    jobType: string;
    extra?: ChatbotResponseExtra;
    data: ChatbotResponseJobTimerData;
};
export type ChatbotResponseJobPlayData = {
    sound: string;
};
export type ChatbotResponseJobPlay = {
    jobType: string;
    extra?: ChatbotResponseExtra;
    data: ChatbotResponseJobPlayData;
};
export type ChatbotResponseJobTerminateData = {
    totalStars: number;
};
export type ChatbotResponseJobTerminate = {
    jobType: string;
    extra?: ChatbotResponseExtra;
    data: ChatbotResponseJobTerminateData;
};
export type ChatbotResponseJob = ({
    jobType: "ChatbotResponseJobChat";
} & ChatbotResponseJobChat) | ({
    jobType: "ChatbotResponseJobSTTStart";
} & ChatbotResponseJobSttStart) | ({
    jobType: "ChatbotResponseJobSTTChange";
} & ChatbotResponseJobSttChange) | ({
    jobType: "ChatbotResponseJobWait";
} & ChatbotResponseJobWait) | ({
    jobType: "ChatbotResponseJobTimer";
} & ChatbotResponseJobTimer) | ({
    jobType: "ChatbotResponseJobPlay";
} & ChatbotResponseJobPlay) | ({
    jobType: "ChatbotResponseJobTerminate";
} & ChatbotResponseJobTerminate);
export type ChatbotResponse = {
    effectiveDuration?: number;
    totalSteps?: number;
    buddy?: string;
    jobs: ChatbotResponseJob[];
};
export type ChatbotRequestChat = {
    text: string;
    mode?: string;
};
export type ChatbotRequestClovaTts = {
    text: string;
    speaker?: string;
    volume?: number;
    speed?: number;
    pitch?: number;
};
export type EntryRequest = {
    title: string;
    response: string;
};
export type EntryListRequest = {
    results: EntryRequest[];
};
export type BillingRequest = {
    planPeriod: number;
    lCode: string;
};
/**
 * 어린이 정보 조회
 */
export function v2ResourcesRetrieveUser({ xClientVersion, xClientOs }: {
    xClientVersion?: string;
    xClientOs?: string;
} = {}, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: UserResponse;
    } | {
        status: 418;
        data: GenericResponse;
    }>("/v2/resources/user", {
        ...opts,
        headers: {
            ...opts && opts.headers,
            "X-CLIENT-VERSION": xClientVersion,
            "X-CLIENT-OS": xClientOs
        }
    });
}
/**
 * 어린이/자녀 정보 수정
 */
export function v2ResourcesPartialUpdateUser(userRequest: UserRequest, { xClientVersion, xClientOs }: {
    xClientVersion?: string;
    xClientOs?: string;
} = {}, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: UserResponse;
    } | {
        status: 418;
        data: GenericResponse;
    }>("/v2/resources/user", oazapfts.json({
        ...opts,
        method: "PATCH",
        body: userRequest,
        headers: {
            ...opts && opts.headers,
            "X-CLIENT-VERSION": xClientVersion,
            "X-CLIENT-OS": xClientOs
        }
    }));
}
/**
 * 자녀 등록
 */
export function v2ResourcesCreateUser(userRequest: UserRequest, { xClientVersion, xClientOs }: {
    xClientVersion?: string;
    xClientOs?: string;
} = {}, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: UserListResponse;
    } | {
        status: 418;
        data: GenericResponse;
    }>("/v2/resources/user", oazapfts.json({
        ...opts,
        method: "POST",
        body: userRequest,
        headers: {
            ...opts && opts.headers,
            "X-CLIENT-VERSION": xClientVersion,
            "X-CLIENT-OS": xClientOs
        }
    }));
}
/**
 * 자녀 삭제
 */
export function v2ResourcesDeleteUser(userUuid: string, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: GenericResponse;
    } | {
        status: 418;
        data: GenericResponse;
    }>(`/v2/resources/user/${userUuid}`, {
        ...opts,
        method: "DELETE"
    });
}
/**
 * 뽀미가 선택한 약속 정보 조회
 */
export function v2ResourcesRetrieveTaskEssential({ xClientVersion, xClientOs }: {
    xClientVersion?: string;
    xClientOs?: string;
} = {}, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: TaskResponse;
    } | {
        status: 418;
        data: GenericResponse;
    }>("/v2/resources/task/essential", {
        ...opts,
        headers: {
            ...opts && opts.headers,
            "X-CLIENT-VERSION": xClientVersion,
            "X-CLIENT-OS": xClientOs
        }
    });
}
/**
 * 내가 고른 약속 대상 과업 리스트
 */
export function v2ResourcesListTaskCandidates({ xClientVersion, xClientOs }: {
    xClientVersion?: string;
    xClientOs?: string;
} = {}, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: TaskListResponse;
    } | {
        status: 418;
        data: GenericResponse;
    }>("/v2/resources/task/candidates", {
        ...opts,
        headers: {
            ...opts && opts.headers,
            "X-CLIENT-VERSION": xClientVersion,
            "X-CLIENT-OS": xClientOs
        }
    });
}
/**
 * 지정한 날짜를 시작 날짜로 하는 약속 타임테이블 적용
 */
export function v2ResourcesApplyScheduleTimetable(scheduleTimetableRequest: ScheduleTimetableRequest, { xClientVersion, xClientOs }: {
    xClientVersion?: string;
    xClientOs?: string;
} = {}, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: ScheduleTimetableResponse;
    } | {
        status: 418;
        data: GenericResponse;
    }>("/v2/resources/schedule/timetable", oazapfts.json({
        ...opts,
        method: "POST",
        body: scheduleTimetableRequest,
        headers: {
            ...opts && opts.headers,
            "X-CLIENT-VERSION": xClientVersion,
            "X-CLIENT-OS": xClientOs
        }
    }));
}
/**
 * 지정한 날짜에 시작하는(없으면 오늘이 포함된) 싸이클의 약속 타임테이블
 */
export function v2ResourcesRetrieveScheduleTimetable({ xClientVersion, xClientOs, startDate }: {
    xClientVersion?: string;
    xClientOs?: string;
    startDate?: string | null;
} = {}, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: ScheduleTimetableResponse;
    } | {
        status: 418;
        data: GenericResponse;
    }>(`/v2/resources/schedule/timetable${QS.query(QS.form({
        startDate
    }))}`, {
        ...opts,
        headers: {
            ...opts && opts.headers,
            "X-CLIENT-VERSION": xClientVersion,
            "X-CLIENT-OS": xClientOs
        }
    });
}
/**
 * 현재 싸이클의 타임테이블을 다음 싸이클로 복제
 */
export function v2ResourcesRepeatScheduleTimetable({ xClientVersion }: {
    xClientVersion?: string;
} = {}, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: ScheduleTimetableResponse;
    } | {
        status: 418;
        data: GenericResponse;
    }>("/v2/resources/schedule/timetable/repeat", {
        ...opts,
        method: "POST",
        headers: {
            ...opts && opts.headers,
            "X-CLIENT-VERSION": xClientVersion
        }
    });
}
/**
 * 지정한 날짜에 시작하는(없으면 오늘이 포함된) 싸이클과 다음 싸이클의 timetable에 대한 알람 정보 조회
 */
export function v2ResourcesScheduleTimetableNoti({ xClientVersion, xClientOs, startDate }: {
    xClientVersion?: string;
    xClientOs?: string;
    startDate?: string | null;
} = {}, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: DashboardNotiResponse;
    } | {
        status: 418;
        data: GenericResponse;
    }>(`/v2/resources/schedule/timetable/noti${QS.query(QS.form({
        startDate
    }))}`, {
        ...opts,
        headers: {
            ...opts && opts.headers,
            "X-CLIENT-VERSION": xClientVersion,
            "X-CLIENT-OS": xClientOs
        }
    });
}
/**
 * 오늘 할일 리스트
 */
export function v2ResourcesRetrieveToDo({ xClientVersion, xClientOs }: {
    xClientVersion?: string;
    xClientOs?: string;
} = {}, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: UserToDoResponse;
    } | {
        status: 418;
        data: GenericResponse;
    }>("/v2/resources/todo", {
        ...opts,
        headers: {
            ...opts && opts.headers,
            "X-CLIENT-VERSION": xClientVersion,
            "X-CLIENT-OS": xClientOs
        }
    });
}
/**
 * 선택 약속 수행 완료
 */
export function v2ResourcesTodoFinish(userTodoFinishRequest: UserTodoFinishRequest, { xClientVersion, xClientOs }: {
    xClientVersion?: string;
    xClientOs?: string;
} = {}, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: UserTodoFinishResponse;
    } | {
        status: 418;
        data: GenericResponse;
    }>("/v2/resources/todo/finish", oazapfts.json({
        ...opts,
        method: "PATCH",
        body: userTodoFinishRequest,
        headers: {
            ...opts && opts.headers,
            "X-CLIENT-VERSION": xClientVersion,
            "X-CLIENT-OS": xClientOs
        }
    }));
}
/**
 * 오늘 약속 수행 현황 조회
 */
export function v2ResourcesRetrieveDashboard({ xClientVersion, xClientOs }: {
    xClientVersion?: string;
    xClientOs?: string;
} = {}, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: DashboardResponse;
    } | {
        status: 418;
        data: GenericResponse;
    }>("/v2/resources/dashboard", {
        ...opts,
        headers: {
            ...opts && opts.headers,
            "X-CLIENT-VERSION": xClientVersion,
            "X-CLIENT-OS": xClientOs
        }
    });
}
/**
 * 지킨 약속 리스트
 */
export function v2ResourcesListJournal({ xClientVersion, xClientOs, itemsPerGroup, appuserUuid }: {
    xClientVersion?: string;
    xClientOs?: string;
    itemsPerGroup?: number | null;
    appuserUuid?: string | null;
} = {}, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: JournalListResponse;
    } | {
        status: 418;
        data: GenericResponse;
    }>(`/v2/resources/journal${QS.query(QS.form({
        itemsPerGroup,
        appuserUUID: appuserUuid
    }))}`, {
        ...opts,
        headers: {
            ...opts && opts.headers,
            "X-CLIENT-VERSION": xClientVersion,
            "X-CLIENT-OS": xClientOs
        }
    });
}
/**
 * 지킨 약속 상세 보기
 */
export function v2ResourcesRetrieveJournalDetail(date: string, { xClientVersion, xClientOs }: {
    xClientVersion?: string;
    xClientOs?: string;
} = {}, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: DashboardResponse;
    } | {
        status: 418;
        data: GenericResponse;
    }>(`/v2/resources/journal/${date}`, {
        ...opts,
        headers: {
            ...opts && opts.headers,
            "X-CLIENT-VERSION": xClientVersion,
            "X-CLIENT-OS": xClientOs
        }
    });
}
/**
 * 자녀 리스트
 */
export function v2ResourcesListUser(xClientModel: string, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: UserListResponse;
    } | {
        status: 418;
        data: GenericResponse;
    }>("/v2/resources/users", {
        ...opts,
        headers: {
            ...opts && opts.headers,
            "X-CLIENT-MODEL": xClientModel
        }
    });
}
/**
 * 메시지 유무 확인
 */
export function v2ResourcesCheckMessage({ xClientVersion, xClientOs }: {
    xClientVersion?: string;
    xClientOs?: string;
} = {}, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: MessageCheckResponse;
    } | {
        status: 418;
        data: GenericResponse;
    }>("/v2/resources/message/check", {
        ...opts,
        headers: {
            ...opts && opts.headers,
            "X-CLIENT-VERSION": xClientVersion,
            "X-CLIENT-OS": xClientOs
        }
    });
}
/**
 * 메시지 상태 수정
 */
export function v2ResourcesUpdateMessageStatus(messageStatusRequest: MessageStatusRequest, { xClientVersion, xClientOs }: {
    xClientVersion?: string;
    xClientOs?: string;
} = {}, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: GenericResponse;
    } | {
        status: 418;
        data: GenericResponse;
    }>("/v2/resources/message/check", oazapfts.json({
        ...opts,
        method: "PATCH",
        body: messageStatusRequest,
        headers: {
            ...opts && opts.headers,
            "X-CLIENT-VERSION": xClientVersion,
            "X-CLIENT-OS": xClientOs
        }
    }));
}
/**
 * 날짜별 메시지 확인
 */
export function v2ResourcesListMessage({ xClientVersion, xClientOs, date, appuserUuid }: {
    xClientVersion?: string;
    xClientOs?: string;
    date?: string | null;
    appuserUuid?: string | null;
} = {}, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: MessageListResponse;
    } | {
        status: 418;
        data: GenericResponse;
    }>(`/v2/resources/message/detail${QS.query(QS.form({
        date,
        appuserUUID: appuserUuid
    }))}`, {
        ...opts,
        headers: {
            ...opts && opts.headers,
            "X-CLIENT-VERSION": xClientVersion,
            "X-CLIENT-OS": xClientOs
        }
    });
}
/**
 * 월별 메시지 개수 확인
 */
export function v2ResourcesRetrieveMessageCount(userUuid: string, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: MessageCountListResponse;
    } | {
        status: 418;
        data: GenericResponse;
    }>(`/v2/resources/message/detail/count/${userUuid}`, {
        ...opts
    });
}
/**
 * 메시지 녹음 파일 저장
 */
export function v2ResourcesStorageMessage(messageRequest: MessageRequest, { xClientVersion }: {
    xClientVersion?: string;
} = {}, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: GenericResponse;
    } | {
        status: 418;
        data: GenericResponse;
    }>("/v2/resources/message/storage", oazapfts.multipart({
        ...opts,
        method: "POST",
        body: messageRequest,
        headers: {
            ...opts && opts.headers,
            "X-CLIENT-VERSION": xClientVersion
        }
    }));
}
/**
 * 기관 리스트
 */
export function v2ResourcesListOrganization(opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: OrganizationListResponse;
    } | {
        status: 418;
        data: GenericResponse;
    }>("/v2/resources/organization", {
        ...opts
    });
}
/**
 * 유치원 알리미 Open API 조회
 */
export function v2ResourceChildSchoolInfo(sidoCode: number, sggCode: number, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: ChildSchoolInfoListResponse;
    } | {
        status: 418;
        data: GenericResponse;
    }>(`/v2/resources/childschoolinfo${QS.query(QS.form({
        sidoCode,
        sggCode
    }))}`, {
        ...opts
    });
}
/**
 * 지난 약속 수행 현황 조회
 */
export function v2ResourcesRetrievePastDashboard(userUuid: string, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: PastDashboardResponse;
    } | {
        status: 418;
        data: GenericResponse;
    }>(`/v2/resources/dashboard/past/${userUuid}`, {
        ...opts
    });
}
/**
 * 부모앱에서의 오늘의 약속 리스트 조회
 */
export function v2ResourcesRetrieveUserDashboard(userUuid: string, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: DashboardResponse;
    } | {
        status: 418;
        data: GenericResponse;
    }>(`/v2/resources/dashboard/${userUuid}`, {
        ...opts
    });
}
/**
 * 워드 클라우드
 */
export function v2ResourcesWordCloud(userUuid: string, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: WordCloudListResponse;
    } | {
        status: 418;
        data: GenericResponse;
    }>(`/v2/resources/wordcloud/${userUuid}`, {
        ...opts
    });
}
/**
 * 모든 사용자 replica 생성
 */
export function v2ResourcesRepeatScheduleReplica(opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: GenericResponse;
    } | {
        status: 418;
        data: GenericResponse;
    }>("/v2/resources/schedule/replica/repeat", {
        ...opts
    });
}
/**
 * 자기조절검사 결과 조회
 */
export function v2ResourcesListAssessment({ xClientVersion, xClientOs }: {
    xClientVersion?: string;
    xClientOs?: string;
} = {}, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: AssessmentListResponse;
    } | {
        status: 418;
        data: GenericResponse;
    }>("/v2/resources/assessment", {
        ...opts,
        headers: {
            ...opts && opts.headers,
            "X-CLIENT-VERSION": xClientVersion,
            "X-CLIENT-OS": xClientOs
        }
    });
}
/**
 * 자기조절검사 답변 업로드
 */
export function v2ResourcesApplyAssessment(assessmentListRequest: AssessmentListRequest, { xClientVersion, xClientOs }: {
    xClientVersion?: string;
    xClientOs?: string;
} = {}, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: AssessmentResponse;
    } | {
        status: 418;
        data: GenericResponse;
    }>("/v2/resources/assessment", oazapfts.json({
        ...opts,
        method: "POST",
        body: assessmentListRequest,
        headers: {
            ...opts && opts.headers,
            "X-CLIENT-VERSION": xClientVersion,
            "X-CLIENT-OS": xClientOs
        }
    }));
}
/**
 * 부모앱 계정 탈퇴
 */
export function v2ResourcesUserWithdrawal(opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: GenericResponse;
    } | {
        status: 418;
        data: GenericResponse;
    }>("/v2/resources/user/withdrawal", {
        ...opts,
        method: "PATCH"
    });
}
/**
 * 바이오마커 파일 저장
 */
export function v2ResourcesStorageBiomarker(biomarkerRequest: BiomarkerRequest, { xClientVersion }: {
    xClientVersion?: string;
} = {}, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: GenericResponse;
    } | {
        status: 418;
        data: GenericResponse;
    }>("/v2/resources/biomarker/storage", oazapfts.multipart({
        ...opts,
        method: "POST",
        body: biomarkerRequest,
        headers: {
            ...opts && opts.headers,
            "X-CLIENT-VERSION": xClientVersion
        }
    }));
}
/**
 * 부모 설문조사 결과 저장
 */
export function v2ResourcesApplySurvey(surveyListRequest: SurveyListRequest, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: GenericResponse;
    } | {
        status: 418;
        data: GenericResponse;
    }>("/v2/resources/survey", oazapfts.json({
        ...opts,
        method: "POST",
        body: surveyListRequest
    }));
}
/**
 * 부모 학교 준비도 평가 결과 저장
 */
export function v2ResourcesApplySchoolSurvey(surveyListRequest: SurveyListRequest, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: GenericResponse;
    } | {
        status: 418;
        data: GenericResponse;
    }>("/v2/resources/survey/school", oazapfts.json({
        ...opts,
        method: "POST",
        body: surveyListRequest
    }));
}
/**
 * CNT 게임 결과 저장
 */
export function v2ResourcesApplyCnt(cntRequest: CntRequest, { xClientVersion, xClientOs }: {
    xClientVersion?: string;
    xClientOs?: string;
} = {}, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: CntResponse;
    } | {
        status: 418;
        data: GenericResponse;
    }>("/v2/resources/cnt", oazapfts.json({
        ...opts,
        method: "POST",
        body: cntRequest,
        headers: {
            ...opts && opts.headers,
            "X-CLIENT-VERSION": xClientVersion,
            "X-CLIENT-OS": xClientOs
        }
    }));
}
/**
 * CNT 게임 결과 조회
 */
export function v2ResourcesRetrieveCnt({ xClientVersion, xClientOs }: {
    xClientVersion?: string;
    xClientOs?: string;
} = {}, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: CntResponse;
    } | {
        status: 418;
        data: GenericResponse;
    }>("/v2/resources/cnt", {
        ...opts,
        headers: {
            ...opts && opts.headers,
            "X-CLIENT-VERSION": xClientVersion,
            "X-CLIENT-OS": xClientOs
        }
    });
}
/**
 * 지난 CNT 게임 결과 조회
 */
export function v2ResourcesRetrievePastCnt(opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: PastCntResponse;
    } | {
        status: 418;
        data: GenericResponse;
    }>("/v2/resources/cnt/past", {
        ...opts
    });
}
/**
 * 사용자 구글폼 신청 정보 검증
 */
export function v2ResourcesUserVerify(userRequest: UserRequest, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: UserVerifyResponse;
    } | {
        status: 418;
        data: GenericResponse;
    }>("/v2/resources/user/verify", oazapfts.json({
        ...opts,
        method: "POST",
        body: userRequest
    }));
}
/**
 * 우리 아이 탭(월별 아이 상태 리포트)
 */
export function v2ResourcesListUserStatusReport(userUuid: string, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: StatusReportListResponse;
    } | {
        status: 418;
        data: GenericResponse;
    }>(`/v2/resources/report/${userUuid}`, {
        ...opts
    });
}
/**
 * 앱 버전 체크
 */
export function v2ResourcesAppVersion({ xClientVersion, xClientOs }: {
    xClientVersion?: string;
    xClientOs?: string;
} = {}, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: AppVersionResponse;
    } | {
        status: 418;
        data: GenericResponse;
    }>("/v2/resources/app/version", {
        ...opts,
        headers: {
            ...opts && opts.headers,
            "X-CLIENT-VERSION": xClientVersion,
            "X-CLIENT-OS": xClientOs
        }
    });
}
/**
 * 세션 destroy(내부 테스트용)
 */
export function v2ResourcesAuthSignOut(opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: GenericResponse;
    } | {
        status: 418;
        data: GenericResponse;
    }>("/v2/resources/auth/signout.do", {
        ...opts,
        method: "POST"
    });
}
/**
 * 라이센스 등록
 */
export function v2LicenseRegister(licenseRegisterRequest: LicenseRegisterRequest, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: GenericResponse;
    } | {
        status: 418;
        data: GenericResponse;
    }>("/v2/license/register.do", oazapfts.json({
        ...opts,
        method: "POST",
        body: licenseRegisterRequest
    }));
}
/**
 * 라이센스 검증
 */
export function v2LicenseVerify(licenseVerifyRequest: LicenseVerifyRequest, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: GenericResponse;
    } | {
        status: 418;
        data: GenericResponse;
    }>("/v2/license/verify.do", oazapfts.json({
        ...opts,
        method: "POST",
        body: licenseVerifyRequest
    }));
}
/**
 * 대화 시작/재개
 */
export function v2ChatbotInitiate(chatbotRequestInitiate: ChatbotRequestInitiate, { xClientVersion }: {
    xClientVersion?: string;
} = {}, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: ChatbotResponse;
    } | {
        status: 418;
        data: GenericResponse;
    }>("/v2/chatbot/initiate.do", oazapfts.json({
        ...opts,
        method: "POST",
        body: chatbotRequestInitiate,
        headers: {
            ...opts && opts.headers,
            "X-CLIENT-VERSION": xClientVersion
        }
    }));
}
/**
 * 대화 중
 */
export function v2ChatbotChat(chatbotRequestChat: ChatbotRequestChat, { xClientVersion }: {
    xClientVersion?: string;
} = {}, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: ChatbotResponse;
    } | {
        status: 418;
        data: GenericResponse;
    }>("/v2/chatbot/chat.do", oazapfts.json({
        ...opts,
        method: "POST",
        body: chatbotRequestChat,
        headers: {
            ...opts && opts.headers,
            "X-CLIENT-VERSION": xClientVersion
        }
    }));
}
/**
 * * CLOVA TTS 요청
 * * https://api.ncloud-docs.com/docs/ai-naver-clovavoice-ttspremium
 *
 */
export function v2ChatbotClovaTts(chatbotRequestClovaTts: ChatbotRequestClovaTts, { xClientVersion }: {
    xClientVersion?: string;
} = {}, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: ChatbotResponseJobChatData;
    } | {
        status: 418;
        data: GenericResponse;
    }>("/v2/chatbot/clova/tts.do", oazapfts.json({
        ...opts,
        method: "POST",
        body: chatbotRequestClovaTts,
        headers: {
            ...opts && opts.headers,
            "X-CLIENT-VERSION": xClientVersion
        }
    }));
}
/**
 * 대화 시작/종료/중단
 */
export function v2ChatbotBehavior(chatbotRequestChat: ChatbotRequestChat, { xClientVersion }: {
    xClientVersion?: string;
} = {}, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: GenericResponse;
    } | {
        status: 418;
        data: GenericResponse;
    }>("/v2/chatbot/behavior.do", oazapfts.json({
        ...opts,
        method: "POST",
        body: chatbotRequestChat,
        headers: {
            ...opts && opts.headers,
            "X-CLIENT-VERSION": xClientVersion
        }
    }));
}
/**
 * 구글폼 참여 신청 리스트 수집
 */
export function v2AppGoogleFormEntry(entryListRequest: EntryListRequest, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: GenericResponse;
    } | {
        status: 418;
        data: GenericResponse;
    }>("/v2/app/google/form/entry.do", oazapfts.json({
        ...opts,
        method: "POST",
        body: entryListRequest
    }));
}
/**
 * 이용권 결제 시, 플랜 기간 연장
 */
export function v2BillingConfirmUser(userUuid: string, billingRequest: BillingRequest, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: GenericResponse;
    } | {
        status: 418;
        data: GenericResponse;
    }>(`/v2/billing/${userUuid}/confirm.do`, oazapfts.json({
        ...opts,
        method: "PATCH",
        body: billingRequest
    }));
}
/**
 * 이용권 환불 시, 연장된 플랜 기간 원복
 */
export function v2BillingCancleUser(userUuid: string, billingRequest: BillingRequest, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: GenericResponse;
    } | {
        status: 418;
        data: GenericResponse;
    }>(`/v2/billing/${userUuid}/cancle.do`, oazapfts.json({
        ...opts,
        method: "PATCH",
        body: billingRequest
    }));
}
