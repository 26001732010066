var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('Title',{staticClass:"compTitle",attrs:{"icon":"DashboardKidIcon","refresh":"","title":"사용 기한 만료 아동"}}),_c('div',{staticClass:"d-flex filter"},[_c('TextField',{staticStyle:{"max-width":"100px"},attrs:{"maxlength":10,"clearable":"","counter":"","label":"이름"},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.onSearch.apply(null, arguments)}},model:{value:(_vm.filter.kidName),callback:function ($$v) {_vm.$set(_vm.filter, "kidName", $$v)},expression:"filter.kidName"}}),_c('v-btn',{attrs:{"color":"primary","icon":""},on:{"click":_vm.onSearch}},[_c('v-icon',[_vm._v("mdi-magnify")])],1),_c('v-btn',{attrs:{"color":"primary","icon":""},on:{"click":_vm.onSearchReset}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.items,"options":_vm.dataOptions,"server-items-length":_vm.total,"dense":"","hide-default-footer":"","item-class":"for_hover"},on:{"update:options":function($event){_vm.dataOptions=$event},"update:sort-desc":_vm.routeChange},scopedSlots:_vm._u([{key:"item.index",fn:function(ref){
var index = ref.index;
return [_c('div',{staticClass:"text-center"},[_vm._v(" "+_vm._s(_vm.$haii().util.dataIndex(_vm.dataOptions, index))+" ")])]}},{key:"item.displayName",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"kidName",on:{"click":function($event){return _vm.onClickKidName(item.UUID)}}},[_vm._v(_vm._s(item.fullName))])]}},{key:"item.planStartDT",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$haii().datetime.toDateString(item.plan.startDate))+" ")]}},{key:"item.planEndDT",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$haii().datetime.toDateString(item.plan.endDate))+" ")]}},{key:"item.diff",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.calcDiff(item.plan.endDate))+" ")]}},{key:"item.organization",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"organization",on:{"click":_vm.onClickOrganization}},[_vm._v(_vm._s(item.organizationName))])]}}],null,true)}),_c('Pagination',{ref:"pagination",staticClass:"pt-2",attrs:{"itemsPerPage":_vm.dataOptions.itemsPerPage,"itemsPerPageOptions":[10, 15, 30, 50, 100],"page":_vm.dataOptions.page,"total":_vm.total},on:{"update":_vm.onPaginationUpdate}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }