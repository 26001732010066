import { DataOptions } from "vuetify";
import { Base64 } from "js-base64";

import { Nested, Primitive } from "@/lib/types";
import { adminAPI, formeAPI } from "@/plugins/haii";
import RouteNames from "@/const/route";
import { DetailInfoBtnType } from "@/components/common/DetailInfoBtns.vue";

interface AgeInterface {
  years: number;
  months: number;
  toString: () => string;
}

type $QueryType = {
  filter?: Nested;
  dataOptions?: DataOptions;
};

class AgeClass implements AgeInterface {
  years = 0;
  months = 0;

  constructor(months: number) {
    this.years = Math.floor(months / 12);
    this.months = months % 12;
  }

  toString(): string {
    return `${this.years}.${this.months}`;
  }
}

class UtilClass {
  static getEmail(
    user?: formeAPI.UserResponse | adminAPI.UserResponse
  ): string {
    let email = "";
    let appendix;

    if (user && user.appendix) {
      try {
        appendix = JSON.parse(user.appendix);
      } catch (err) {
        // eslint-disable-next-line no-console
        console.error(err, user.appendix);
      }
    }

    if (appendix && appendix.email) {
      email = appendix.email;
    }

    return email;
  }

  static getAge(birthday: Date): AgeInterface {
    const now = new Date();
    const months =
      now.getFullYear() * 12 +
      now.getMonth() -
      birthday.getFullYear() * 12 -
      birthday.getMonth();
    return new AgeClass(months);
  }

  static dataIndex(dataOptions: DataOptions, index: number): number {
    return dataOptions.itemsPerPage * (dataOptions.page - 1) + index + 1;
  }

  static queryEncode(data: any): string {
    return Base64.encode(JSON.stringify(data));
  }

  static queryDecode(data: string): any {
    return JSON.parse(Base64.decode(data));
  }

  static listQueryEncode(filter?: Nested, dataOptions?: DataOptions): string {
    return Base64.encode(JSON.stringify({ filter, dataOptions }));
  }

  static listQueryDecode(
    encoded: string | Array<string | null>,
    defaultFilter?: Nested,
    defaultDataOptions?: DataOptions
  ): $QueryType | Error {
    let query = {} as $QueryType;
    let invalidFilter: string | boolean = false;
    let invalidFilterValue: Primitive | Nested | Array<Primitive | Nested>;
    let invalidDataOptions: string | boolean = false;
    let invalidDataOptionsValue: Primitive | Nested | Array<Primitive | Nested>;
    let err: Error | undefined;

    if (!encoded) {
      return query;
    }

    try {
      query = JSON.parse(Base64.decode(encoded.toString()));

      if (query.filter) {
        if (defaultFilter) {
          Object.entries(query.filter).forEach(([key, value]) => {
            if (value === null) {
              return;
            }
            if (
              typeof value !==
              typeof defaultFilter[key as keyof typeof defaultFilter]
            ) {
              invalidFilter = key;
              invalidFilterValue = value;
            }
          });
        } else {
          invalidFilter = "DEFAULT_FILTER";
        }
      }

      if (query.dataOptions) {
        if (defaultDataOptions) {
          Object.entries(query.dataOptions).forEach(([key, value]) => {
            if (
              typeof value !==
              typeof defaultDataOptions[key as keyof typeof defaultDataOptions]
            ) {
              invalidDataOptions = key;
              invalidDataOptionsValue = value;
            }
          });
        } else {
          invalidDataOptions = "DEFAULT_DATA_OPTIONS";
        }
      }
    } catch (e) {
      err = e as Error;
    } finally {
      if (invalidFilter) {
        err = Error(`Invalid filter.${invalidFilter}: ${invalidFilterValue}`);
      } else if (invalidDataOptions) {
        err = Error(
          `Invalid dataOptions.${invalidDataOptions}: ${invalidDataOptionsValue}`
        );
      }
    }

    return err || query;
  }

  static statColor(rate: number | string): string {
    const p = typeof rate === "number" ? rate : parseInt(rate, 10);

    if (p >= 75) {
      return "success";
    } else if (p >= 50) {
      return "info";
    }

    return p === 0 ? "error" : "warning";
  }

  static koSubjectiveCase(word: string): boolean {
    const lastCharCode = word.charCodeAt(word.length - 1);
    const lastConsonant = (lastCharCode - 44032) % 28;
    return !!lastConsonant;
  }

  // string 값
  static getPhoneMaxLength(phone: string): number {
    const regExp = /(\d{10,11})/g;
    if (regExp.test(phone)) {
      return 11;
    }
    return 13;
  }

  static getDetailInfoBtns(type: "KID" | "ORGANIZATION"):DetailInfoBtnType[] {
    const isKid = type === "KID";

    return [
      {
        key: "약속 진행",
        icon: "mdi-star",
        name: isKid ? RouteNames.KID_HISTORY_LIST : RouteNames.ORGANIZATION_HISTORY_LIST
      },
      {
        key: "선택 약속",
        icon: "$vuetify.icons.TiltingPinIcon",
        name: isKid ? RouteNames.KID_CHOOSE_PROMISE_LIST:RouteNames.ORGANIZATION_CHOOSE_PROMISE_LIST
      },
      {
        key: "하루 일기",
        icon: "$vuetify.icons.DiaryIcon",
        name: isKid ? RouteNames.KID_DAY_DAIRY_LIST:RouteNames.ORGANIZATION_DAY_DAIRY_LIST
      },
      {
        key: "설문 조사",
        icon: "$vuetify.icons.SurveyIcon",
        name: isKid ? RouteNames.KID_SURVEY_LIST:RouteNames.ORGANIZATION_SURVEY_LIST
      },
      {
        key: "뽀미 도와주기",
        icon: "$vuetify.icons.ChartIcon",
        name: isKid ? RouteNames.KID_HELP_FORME_LIST:RouteNames.ORGANIZATION_HELP_FORME_LIST
      }
    ];
  }
}

export default UtilClass;
