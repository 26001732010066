import { AdminResponse, OrganizationResponse } from "@/generated/adminapi";
import { KidInterface } from "@/views/KidList/Kid.vue";


export interface SettingInterface {
  locale: string;
  darkTheme: boolean;
  drawer: boolean;
  timeline: boolean;
  calendar: boolean;
  devops: boolean;
  moveHome: boolean;
}

export interface AlertDialogInterface {
  unauthorized: boolean;
  forbidden: boolean;
  apiError: boolean;
  message: string | Error | unknown;
  noAuthority: boolean;
}

export interface ConfirmDialogInterface {
  KidHistoryList: boolean;
  width: number;
}

interface RootState {
  setting: SettingInterface; // window.localStorage
  admin: AdminResponse; // window.sessionStorage
  alertDialog: AlertDialogInterface;
  confirmDialog: ConfirmDialogInterface;
  recentKid: KidInterface
  loading: boolean;
  organizationList: OrganizationResponse[];
  firstGroup : string[]
}

const state: RootState = {
  setting: {
    locale: "ko",
    darkTheme: false,
    drawer: true,
    timeline: true,
    calendar: true,
    devops: false,
    moveHome: false,
  },
  admin: {
    UUID: "",
    displayName: "",
    email: "",
  },
  alertDialog: {
    unauthorized: false,
    forbidden: false,
    apiError: false,
    message: "",
    noAuthority: false,
  },
  confirmDialog: {
    KidHistoryList: false,
    width: 400,
  },
  recentKid : {} as KidInterface,
  loading: false,
  organizationList: {} as OrganizationResponse[],
  firstGroup : []
};

export { state, RootState };
