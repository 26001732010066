import type { DataOptions } from "vuetify";
import * as Oazapfts from "oazapfts/lib/runtime";
import * as adminAPI from "@/generated/adminapi";
import {
  AdminAssessmentListResponse,
  AdminCntListResponse,
  AdminCntOrganizationListResponse,
  AdminListRequest,
  AdminListResponse,
  AdminMessageListResponse,
  AdminRequest,
  AdminResponse,
  AdminSurveyListResponse,
  AppointmentListResponse,
  GenericResponse,
  HistoryListResponse,
  HistoryLogListResponse,
  HistoryOrganizationListResponse,
  HistoryResponse,
  LicenseListRequest,
  LicenseListResponse,
  LicenseUpdateRequest,
  NoticeRequest,
  OrganizationDetailStatsResponse,
  OrganizationListResponse,
  OrganizationStatsRequest,
  OrganizationStatsResponse,
  StatisticsListRequest,
  StatisticsListResponse,
  TaskListResponse,
  UserListRequest,
  UserListResponse,
  UserResponse
} from "@/generated/adminapi";
import store from "@/store";
import { ActionTypes } from "@/store/actions";
import { HaiiAuth } from "@/plugins/haii/HaiiExAuth";
import cookie from "js-cookie";

type TableParamType = Parameters<typeof adminAPI.v2AdminListKidUnscheduled>[0] &
  Parameters<typeof adminAPI.v2AdminListHistory>[0] &
  Parameters<typeof adminAPI.v2AdminListKid>[0] &
  Parameters<typeof adminAPI.v2AdminListStatistics>[0];

export type DeleteParamType = {
  UUID?: string;
  faUID?: string; // appuser only
};

type serverType = "forme" | "admin";

class ServerClass {
  readonly baseUrl: string;

  constructor(apiSet: serverType) {
    if (apiSet == "admin") {
      this.baseUrl = process.env.VUE_APP_ADMIN_API_SERVER_URL;
    } else {
      this.baseUrl = process.env.VUE_APP_FORME_API_SERVER_URL;
    }
  }

  protected get requestOptions(): Oazapfts.RequestOpts {
    const jwt = cookie.get("_aat");

    return {
      baseUrl: this.baseUrl,
      headers: {
        Authorization: `Bearer ${jwt}`
      }
    };
  }

  async manageToken() {
    await HaiiAuth.Instance.manageToken(true);
  }

  async handleResponse(
    response: Error | GenericResponse | any
  ): Promise<string> {
    if (response instanceof Error) {
      if (process.env.VUE_APP_ENV != "production") {
        await store.dispatch(ActionTypes.ALERT_DIALOG_SET, {
          apiError: true,
          message: response
        });
      }
      return response + "";
    }

    if ((response.data as GenericResponse).code) {
      if (process.env.VUE_APP_ENV != "production") {
        await store.dispatch(ActionTypes.ALERT_DIALOG_SET, {
          apiError: true,
          message: response.data.message
        });
      }
    }

    return response.data.message + "";
  }

  // 빈 값 체크
  checkEmptyValue(object?: any) {
    if (!object) return;

    // 키 추출
    const keyList = Object.keys(object);

    // 없으면 리턴
    if (!Array.isArray(keyList)) return;

    // object[key] 값이 없으면 value 를 undefined 로 변경
    keyList.forEach((key) => {
      if (typeof object[key] == "boolean") {
        return;
      }

      if (typeof object[key] == "string") {
        return;
      }

      if (!object[key]) {
        object[key] = undefined;
      }
    });

    return object;
  }

  setTableParam(filter?: any, dataOptions?: DataOptions) {
    const newParam: TableParamType = {};

    filter = this.checkEmptyValue(filter);

    if (filter) {
      newParam.filter = JSON.stringify(filter);
    }

    if (dataOptions) {
      if (dataOptions.sortBy.length) {
        newParam.sorting = JSON.stringify({
          keys: dataOptions.sortBy,
          dirs: dataOptions.sortDesc.map((desc: boolean): string =>
            desc ? "DESC" : "ASC"
          )
        });
      }

      // All 선택하면 itemsPerPage = -1
      if (dataOptions.itemsPerPage > 0) {
        newParam.pagination = JSON.stringify({
          limit: dataOptions.itemsPerPage,
          page: dataOptions.page
        });
      }
    }
    return newParam;
  }
}

export class APIServerClass extends ServerClass {
  constructor() {
    super("forme");
  }
}

export class AdminServerClass extends ServerClass {
  constructor() {
    super("admin");
  }

  protected get requestOptions(): Oazapfts.RequestOpts {
    const jwt = cookie.get("_aat");

    return {
      baseUrl: this.baseUrl,
      headers: {
        "X-AdminAuthorization": `Bearer ${jwt}`
      }
    };
  }

  async v2AdminListKidUnscheduled(
    filter?: any,
    dataOptions?: DataOptions
  ): Promise<UserListResponse | string> {
    try {
      await this.manageToken();

      const newParam = this.setTableParam(filter, dataOptions);

      const res = await adminAPI.v2AdminListKidUnscheduled(
        newParam,
        this.requestOptions
      );
      if (res.status != 200) {
        return await this.handleResponse(res);
      }
      return res.data;
    } catch (e) {
      return e + "";
    }
  }

  async v2AdminListHistory(
    filter?: any,
    dataOptions?: DataOptions
  ): Promise<HistoryListResponse | string> {
    try {
      await this.manageToken();

      const newParam = this.setTableParam(filter, dataOptions);

      const res = await adminAPI.v2AdminListHistory(
        { ...newParam, devops: true },
        this.requestOptions
      );
      if (res.status != 200) {
        return await this.handleResponse(res);
      }
      return res.data;
    } catch (e) {
      return e + "";
    }
  }

  async v2AdminListKid(
    userListRequest: UserListRequest,
    filter?: any,
    dataOptions?: DataOptions
  ): Promise<UserListResponse | string> {
    try {
      await this.manageToken();
      const newParam = this.setTableParam(filter, dataOptions);

      const res = await adminAPI.v2AdminListKid(
        userListRequest,
        { details: true, ...newParam },
        this.requestOptions
      );
      if (res.status != 200) {
        return await this.handleResponse(res);
      }
      return res.data;
    } catch (e) {
      return e + "";
    }
  }

  async v2AdminListStatistics(
    statisticsListRequest: StatisticsListRequest,
    filter?: any,
    dataOptions?: DataOptions
  ): Promise<StatisticsListResponse | string> {
    try {
      await this.manageToken();

      const newParam = this.setTableParam(filter, dataOptions);

      const res = await adminAPI.v2AdminListStatistics(
        statisticsListRequest,
        newParam,
        this.requestOptions
      );
      if (res.status != 200) {
        return await this.handleResponse(res);
      }
      return res.data;
    } catch (e) {
      return e + "";
    }
  }

  async v2AdminRetrieveHistory(
    historyUuid: string
  ): Promise<HistoryResponse | string> {
    try {
      await this.manageToken();
      const res = await adminAPI.v2AdminRetrieveHistory(
        historyUuid,
        this.requestOptions
      );
      if (res.status != 200) {
        return await this.handleResponse(res);
      }
      return res.data;
    } catch (e) {
      return e + "";
    }
  }

  async v2AdminRetrieveKid(userId: string): Promise<UserResponse | string> {
    try {
      await this.manageToken();
      const res = await adminAPI.v2AdminRetrieveKid(
        userId,
        this.requestOptions
      );
      if (res.status != 200) {
        return await this.handleResponse(res);
      }
      return res.data;
    } catch (e) {
      return e + "";
    }
  }

  async v2AdminListHistoryLog(
    historyUuid: string
  ): Promise<HistoryLogListResponse | string> {
    try {
      await this.manageToken();
      const res = await adminAPI.v2AdminListHistoryLog(
        historyUuid,
        this.requestOptions
      );
      if (res.status != 200) {
        return await this.handleResponse(res);
      }
      return res.data;
    } catch (e) {
      return e + "";
    }
  }

  async v2AdminListTask(): Promise<TaskListResponse | string> {
    try {
      await this.manageToken();
      const res = await adminAPI.v2AdminListTask(this.requestOptions);
      if (res.status != 200) {
        return await this.handleResponse(res);
      }
      return res.data;
    } catch (e) {
      return e + "";
    }
  }

  async v2AdminDeleteKid(userId: string): Promise<GenericResponse | string> {
    try {
      await this.manageToken();
      const res = await adminAPI.v2AdminDeleteKid(userId, this.requestOptions);
      if (res.status != 200) {
        return await this.handleResponse(res);
      }
      return res.data;
    } catch (e) {
      return e + "";
    }
  }

  async v2AdminDeleteHistory(
    historyUuid: string
  ): Promise<GenericResponse | string> {
    try {
      await this.manageToken();
      const res = await adminAPI.v2AdminDeleteHistory(
        historyUuid,
        this.requestOptions
      );
      if (res.status != 200) {
        return await this.handleResponse(res);
      }
      return res.data;
    } catch (e) {
      return e + "";
    }
  }

  async v2AdminListUser(
    filter?: any,
    dataOptions?: DataOptions
  ): Promise<AdminListResponse | string> {
    try {
      await this.manageToken();
      const newParam = this.setTableParam(filter, dataOptions);
      const res = await adminAPI.v2AdminListUser(newParam, this.requestOptions);
      if (res.status != 200) {
        return await this.handleResponse(res);
      }
      return res.data;
    } catch (e) {
      return e + "";
    }
  }

  async v2AdminListOrganization(
    model?: string
  ): Promise<OrganizationListResponse | string> {
    try {
      await this.manageToken();
      const res = await adminAPI.v2AdminListOrganization(
        { filter: JSON.stringify({ model }) },
        this.requestOptions
      );
      if (res.status != 200) {
        return await this.handleResponse(res);
      }
      return res.data;
    } catch (e) {
      return e + "";
    }
  }

  async v2AdminUpdateUser(
    adminUuid: string,
    adminRequest: AdminRequest
  ): Promise<GenericResponse | string> {
    try {
      await this.manageToken();

      const res = await adminAPI.v2AdminUpdateUser(
        adminUuid,
        adminRequest,
        this.requestOptions
      );
      if (res.status != 200) {
        return await this.handleResponse(res);
      }
      return res.data;
    } catch (e) {
      return e + "";
    }
  }

  async v2AdminCreateUser(
    adminListRequest: AdminListRequest
  ): Promise<GenericResponse | string> {
    try {
      await this.manageToken();

      const res = await adminAPI.v2AdminCreateUser(
        adminListRequest,
        this.requestOptions
      );

      if (res.status != 200) {
        return await this.handleResponse(res);
      }
      return res.data;
    } catch (e) {
      return e + "";
    }
  }

  async v2AdminRetrieveUser(): Promise<AdminResponse | string> {
    try {
      await this.manageToken();

      const res = await adminAPI.v2AdminRetrieveUser(this.requestOptions);

      if (res.status != 200) {
        return await this.handleResponse(res);
      }
      return res.data;
    } catch (e) {
      return e + "";
    }
  }

  async v2AuthSignOut(): Promise<GenericResponse | string> {
    try {
      await this.manageToken();

      const res = await adminAPI.v2AuthSignOut(this.requestOptions);

      if (res.status != 200) {
        return await this.handleResponse(res);
      }
      return res.data;
    } catch (e) {
      return e + "";
    }
  }

  async v2AdminPushNotice(
    noticeRequest: NoticeRequest
  ): Promise<GenericResponse | string> {
    try {
      await this.manageToken();

      const res = await adminAPI.v2AdminPushNotice(
        noticeRequest,
        this.requestOptions
      );

      if (res.status != 200) {
        return await this.handleResponse(res);
      }
      return res.data;
    } catch (e) {
      return e + "";
    }
  }

  async v2AdminListLicense(
    licenseListRequest: LicenseListRequest,
    filter?: any,
    dataOptions?: DataOptions
  ): Promise<LicenseListResponse | string> {
    try {
      await this.manageToken();
      const newParam = this.setTableParam(filter, dataOptions);

      const res = await adminAPI.v2AdminListLicense(
        licenseListRequest,
        newParam,
        this.requestOptions
      );

      if (res.status != 200) {
        return await this.handleResponse(res);
      }
      return res.data;
    } catch (e) {
      return e + "";
    }
  }

  async v2AdminUpdateLicense(
    licenseUuid: string, licenseUpdateRequest: LicenseUpdateRequest
  ): Promise<GenericResponse | string> {
    try {
      await this.manageToken();

      const res = await adminAPI.v2AdminUpdateLicense(
        licenseUuid,
        licenseUpdateRequest,
        this.requestOptions
      );

      if (res.status != 200) {
        return await this.handleResponse(res);
      }
      return res.data;
    } catch (e) {
      return e + "";
    }
  }

  async v2AdminListKidExcelDownload(
    userListRequest: UserListRequest,
    filter?: any,
    dataOptions?: DataOptions
  ): Promise<Blob | string> {
    try {
      await this.manageToken();
      const newParam = this.setTableParam(filter, dataOptions);
      const res = await adminAPI.v2AdminListKidExcelDownload(
        userListRequest,
        { details: true, ...newParam },
        this.requestOptions
      );

      if (res.status != 200) {
        return await this.handleResponse(res);
      }
      return res.data;
    } catch (e) {
      return e + "";
    }
  }

  async v2AdminListHistoryExcelDownload(
    filter?: any,
    dataOptions?: DataOptions
  ): Promise<Blob | string> {
    try {
      await this.manageToken();
      const newParam = this.setTableParam(filter, dataOptions);
      const res = await adminAPI.v2AdminListHistoryExcelDownload(
        { devops: true, ...newParam },
        this.requestOptions
      );

      if (res.status != 200) {
        return await this.handleResponse(res);
      }
      return res.data;
    } catch (e) {
      return e + "";
    }
  }

  async v2AdminScheduleTimetable(
    filter?: any,
    dataOptions?: DataOptions
  ): Promise<AppointmentListResponse | string> {
    try {
      await this.manageToken();
      const newParam = this.setTableParam(filter, dataOptions);
      const res = await adminAPI.v2AdminScheduleTimetable(
        { ...newParam },
        this.requestOptions
      );

      if (res.status != 200) {
        return await this.handleResponse(res);
      }
      return res.data;
    } catch (e) {
      return e + "";
    }
  }

  async v2AdminListMessage(
    filter?: any,
    dataOptions?: DataOptions
  ): Promise<AdminMessageListResponse | string> {
    try {
      await this.manageToken();
      const newParam = this.setTableParam(filter, dataOptions);
      const res = await adminAPI.v2AdminListMessage(
        { ...newParam },
        this.requestOptions
      );

      if (res.status != 200) {
        return await this.handleResponse(res);
      }
      return res.data;
    } catch (e) {
      return e + "";
    }
  }

  async v2AdminSurvey(
    schoolFlag?: boolean | null,
    filter?: any,
    dataOptions?: DataOptions
  ): Promise<AdminSurveyListResponse | string> {
    try {
      await this.manageToken();
      const newParam = this.setTableParam(filter, dataOptions);
      const res = await adminAPI.v2AdminSurvey(
        { schoolFlag, ...newParam },
        this.requestOptions
      );

      if (res.status != 200) {
        return await this.handleResponse(res);
      }
      return res.data;
    } catch (e) {
      return e + "";
    }
  }

  async v2AdminAssessment(
    filter?: any,
    dataOptions?: DataOptions
  ): Promise<AdminAssessmentListResponse | string> {
    try {
      await this.manageToken();
      const newParam = this.setTableParam(filter, dataOptions);
      const res = await adminAPI.v2AdminAssessment(
        { ...newParam },
        this.requestOptions
      );

      if (res.status != 200) {
        return await this.handleResponse(res);
      }
      return res.data;
    } catch (e) {
      return e + "";
    }
  }

  async v2AdminScheduleTimeTableExcelDownload(
    filter?: any,
    dataOptions?: DataOptions
  ): Promise<Blob | string> {
    try {
      await this.manageToken();
      const newParam = this.setTableParam(filter, dataOptions);
      const res = await adminAPI.v2AdminScheduleTimeTableExcelDownload(
        { ...newParam },
        this.requestOptions
      );

      if (res.status != 200) {
        return await this.handleResponse(res);
      }
      return res.data;
    } catch (e) {
      return e + "";
    }
  }

  async v2AdminMessageExcelDownload(
    filter?: any,
    dataOptions?: DataOptions
  ): Promise<Blob | string> {
    try {
      await this.manageToken();
      const newParam = this.setTableParam(filter, dataOptions);
      const res = await adminAPI.v2AdminMessageExcelDownload(
        { ...newParam },
        this.requestOptions
      );

      if (res.status != 200) {
        return await this.handleResponse(res);
      }
      return res.data;
    } catch (e) {
      return e + "";
    }
  }

  async v2AdminSurveyExcelDownload(
    schoolFlag?: boolean | null,
    filter?: any,
    dataOptions?: DataOptions
  ): Promise<Blob | string> {
    try {
      await this.manageToken();
      const newParam = this.setTableParam(filter, dataOptions);
      const res = await adminAPI.v2AdminSurveyExcelDownload(
        { schoolFlag, ...newParam },
        this.requestOptions
      );

      if (res.status != 200) {
        return await this.handleResponse(res);
      }
      return res.data;
    } catch (e) {
      return e + "";
    }
  }

  async v2AdminAssessmentExcelDownload(
    filter?: any,
    dataOptions?: DataOptions
  ): Promise<Blob | string> {
    try {
      await this.manageToken();
      const newParam = this.setTableParam(filter, dataOptions);
      const res = await adminAPI.v2AdminAssessmentExcelDownload(
        { ...newParam },
        this.requestOptions
      );

      if (res.status != 200) {
        return await this.handleResponse(res);
      }
      return res.data;
    } catch (e) {
      return e + "";
    }
  }

  async v2AdminOrganizationStats(
    organizationStatsRequest: OrganizationStatsRequest,
    filter?: any,
    dataOptions?: DataOptions
  ): Promise<OrganizationStatsResponse | string> {
    try {
      await this.manageToken();
      const newParam = this.setTableParam(filter, dataOptions);
      const res = await adminAPI.v2AdminOrganizationStats(
        organizationStatsRequest,
        { ...newParam },
        this.requestOptions
      );

      if (res.status != 200) {
        return await this.handleResponse(res);
      }
      return res.data;
    } catch (e) {
      return e + "";
    }
  }

  async v2AdminOrganizationDetailStats(
    filter?: any,
    dataOptions?: DataOptions
  ): Promise<OrganizationDetailStatsResponse | string> {
    try {
      await this.manageToken();
      const newParam = this.setTableParam(filter, dataOptions);
      const res = await adminAPI.v2AdminOrganizationDetailStats(
        { ...newParam },
        this.requestOptions
      );

      if (res.status != 200) {
        return await this.handleResponse(res);
      }
      return res.data;
    } catch (e) {
      return e + "";
    }
  }

  async v2AdminListHistoryOrganization(
    filter?: any,
    dataOptions?: DataOptions
  ): Promise<HistoryOrganizationListResponse | string> {
    try {
      await this.manageToken();
      const newParam = this.setTableParam(filter, dataOptions);
      const res = await adminAPI.v2AdminListHistoryOrganization(
        { ...newParam },
        this.requestOptions
      );

      if (res.status != 200) {
        return await this.handleResponse(res);
      }
      return res.data;
    } catch (e) {
      return e + "";
    }
  }

  async v2AdminOrganizationStatsExcelDownload(
    organizationStatsRequest: OrganizationStatsRequest,
    filter?: any,
    dataOptions?: DataOptions
  ): Promise<Blob | string> {
    try {
      await this.manageToken();
      const newParam = this.setTableParam(filter, dataOptions);
      const res = await adminAPI.v2AdminOrganizationStatsExcelDownload(
        organizationStatsRequest,
        { ...newParam },
        this.requestOptions
      );

      if (res.status != 200) {
        return await this.handleResponse(res);
      }
      return res.data;
    } catch (e) {
      return e + "";
    }
  }

  async v2AdminListHistoryOrganizationExcelDownload(
    filter?: any,
    dataOptions?: DataOptions
  ): Promise<Blob | string> {
    try {
      await this.manageToken();
      const newParam = this.setTableParam(filter, dataOptions);
      const res = await adminAPI.v2AdminListHistoryOrganizationExcelDownload(
        { ...newParam },
        this.requestOptions
      );

      if (res.status != 200) {
        return await this.handleResponse(res);
      }
      return res.data;
    } catch (e) {
      return e + "";
    }
  }

  async v2AdminRetrieveCnt(
    userId: string,
    filter?: any,
    dataOptions?: DataOptions
  ): Promise<AdminCntListResponse | string> {
    try {
      await this.manageToken();
      const newParam = this.setTableParam(filter, dataOptions);
      const res = await adminAPI.v2AdminRetrieveCnt(
        userId,
        { ...newParam },
        this.requestOptions
      );

      if (res.status != 200) {
        return await this.handleResponse(res);
      }
      return res.data;
    } catch (e) {
      return e + "";
    }
  }

  async v2AdminListCntExcelDownload(
    userId: string,
    filter?: any,
    dataOptions?: DataOptions
  ): Promise<Blob | string> {
    try {
      await this.manageToken();
      const newParam = this.setTableParam(filter, dataOptions);
      const res = await adminAPI.v2AdminRetrieveCntExcelDownload(
        userId,
        { ...newParam },
        this.requestOptions
      );

      if (res.status != 200) {
        return await this.handleResponse(res);
      }
      return res.data;
    } catch (e) {
      return e + "";
    }
  }

  async v2AdminListCntOrganization(
    filter?: any,
    dataOptions?: DataOptions
  ): Promise<AdminCntOrganizationListResponse | string> {
    try {
      await this.manageToken();
      const newParam = this.setTableParam(filter, dataOptions);
      const res = await adminAPI.v2AdminListCntOrganization(
        { ...newParam },
        this.requestOptions
      );

      if (res.status != 200) {
        return await this.handleResponse(res);
      }
      return res.data;
    } catch (e) {
      return e + "";
    }
  }

  async v2AdminListCntOrganizationExcelDownload(
    filter?: any,
    dataOptions?: DataOptions
  ): Promise<Blob | string> {
    try {
      await this.manageToken();
      const newParam = this.setTableParam(filter, dataOptions);
      const res = await adminAPI.v2AdminListCntOrganizationExcelDownload(
        { ...newParam },
        this.requestOptions
      );

      if (res.status != 200) {
        return await this.handleResponse(res);
      }
      return res.data;
    } catch (e) {
      return e + "";
    }
  }

}
