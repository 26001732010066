<template>
  <svg class="customIcon" fill="white" height="26" viewBox="0 0 27 26" width="27" xmlns="http://www.w3.org/2000/svg">
    <path clip-rule="evenodd" d="M6.88235 0C6.19591 0 5.53758 0.273928 5.05219 0.761522C4.56681 1.24912 4.29412 1.91044 4.29412 2.6V3.9C3.9509 3.9 3.62173 4.03696 3.37904 4.28076C3.13634 4.52456 3 4.85522 3 5.2C3 5.54478 3.13634 5.87544 3.37904 6.11924C3.62173 6.36304 3.9509 6.5 4.29412 6.5V9.1C3.9509 9.1 3.62173 9.23696 3.37904 9.48076C3.13634 9.72456 3 10.0552 3 10.4C3 10.7448 3.13634 11.0754 3.37904 11.3192C3.62173 11.563 3.9509 11.7 4.29412 11.7V14.3C3.9509 14.3 3.62173 14.437 3.37904 14.6808C3.13634 14.9246 3 15.2552 3 15.6C3 15.9448 3.13634 16.2754 3.37904 16.5192C3.62173 16.763 3.9509 16.9 4.29412 16.9V19.5C3.9509 19.5 3.62173 19.637 3.37904 19.8808C3.13634 20.1246 3 20.4552 3 20.8C3 21.1448 3.13634 21.4754 3.37904 21.7192C3.62173 21.963 3.9509 22.1 4.29412 22.1V23.4C4.29412 24.0896 4.56681 24.7509 5.05219 25.2385C5.53758 25.7261 6.19591 26 6.88235 26H22.4118C23.0982 26 23.7565 25.7261 24.2419 25.2385C24.7273 24.7509 25 24.0896 25 23.4V2.6C25 1.91044 24.7273 1.24912 24.2419 0.761522C23.7565 0.273928 23.0982 0 22.4118 0H6.88235ZM10.1176 5.2C9.60282 5.2 9.10907 5.40545 8.74503 5.77114C8.38099 6.13684 8.17647 6.63283 8.17647 7.15V8.45C8.17647 8.96717 8.38099 9.46316 8.74503 9.82886C9.10907 10.1946 9.60282 10.4 10.1176 10.4H19.1765C19.6913 10.4 20.185 10.1946 20.5491 9.82886C20.9131 9.46316 21.1176 8.96717 21.1176 8.45V7.15C21.1176 6.63283 20.9131 6.13684 20.5491 5.77114C20.185 5.40545 19.6913 5.2 19.1765 5.2H10.1176Z"  fill-rule="evenodd"/>
  </svg>

</template>

<script>
export default { name: "DiaryIcon" };
</script>
