<template>
  <svg fill="none" height="22" viewBox="0 0 22 22" width="22" xmlns="http://www.w3.org/2000/svg">
    <circle cx="11" cy="11" fill="#CBE0FF" r="11"/>
    <path d="M7.07143 9.42857C7.93931 9.42857 8.64286 8.54913 8.64286 7.46429C8.64286 6.37944 7.93931 5.5 7.07143 5.5C6.20355 5.5 5.5 6.37944 5.5 7.46429C5.5 8.54913 6.20355 9.42857 7.07143 9.42857Z" fill="#514B4B"/>
    <path d="M14.9289 9.42857C15.7967 9.42857 16.5003 8.54913 16.5003 7.46429C16.5003 6.37944 15.7967 5.5 14.9289 5.5C14.061 5.5 13.3574 6.37944 13.3574 7.46429C13.3574 8.54913 14.061 9.42857 14.9289 9.42857Z" fill="#514B4B"/>
    <path d="M14.9186 15.3239C14.9107 15.2963 14.1007 12.5714 11.0006 12.5714C7.90043 12.5714 7.0904 15.2963 7.08254 15.3239C7.04468 15.4578 7.11397 15.5948 7.24826 15.6659C7.38256 15.7363 7.55756 15.7199 7.674 15.6282C7.68043 15.6238 8.3976 15.0857 11.0006 15.0857C13.5649 15.0857 14.2986 15.6074 14.3264 15.6282C14.3943 15.6854 14.4828 15.7143 14.5721 15.7143C14.6321 15.7143 14.6929 15.7011 14.7479 15.674C14.8879 15.6043 14.9579 15.461 14.9186 15.3239Z" fill="#514B4B"/>
    <path d="M10.2148 15.1905C10.2148 16.7819 8.98394 18.0714 7.46484 18.0714C5.94574 18.0714 4.71484 16.7819 4.71484 15.1905C4.71484 13.5991 6.91484 9.42859 7.46484 9.42859C8.01484 9.42859 10.2148 13.5991 10.2148 15.1905Z" fill="#7DB1FF"/>
  </svg>

</template>

<script>
export default { name: "SorrowIcon" };
</script>
