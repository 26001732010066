import Vue from "vue";
import * as Sentry from "@sentry/vue";
import router from "@/router";

export function sentryInit() {
  if (process.env.VUE_APP_ENV === "localhost") return;
  Sentry.init({
    Vue,
    dsn: "https://c1d6424d5468a8960154940bc41f10c8@o4507586466283520.ingest.us.sentry.io/4507621750472704",
    integrations: [
      Sentry.browserTracingIntegration({ router }),
      Sentry.replayIntegration()
    ],

    normalizeDepth: 4,

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for tracing.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,

    // Set `tracePropagationTargets` to control for which URLs trace propagation should be enabled
    tracePropagationTargets: [
      "localhost",
      /https:\/\/.*\.haii\.io/,
      /https:\/\/.*\.co.kr/
    ],

    // Capture Replay for 10% of all sessions,
    // plus for 100% of sessions with an error
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
    environment: process.env.VUE_APP_ENV
  });
}

