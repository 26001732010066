import Vue from "vue";
import Vuetify from "vuetify/lib/framework";
import { DARK_PALETTE, LIGHT_PALETTE } from "@/plugins/haii";
import locale from "@/plugins/locale";
import FormeLogo from "@/assets/icons/FormeLogo.vue";
import AppPushIcon from "@/assets/icons/drawerIcon/AppPushIcon.vue";
import HomeIcon from "@/assets/icons/drawerIcon/HomeIcon.vue";
import PromiseIcon from "@/assets/icons/drawerIcon/PromiseIcon.vue";
import UserManagementIcon from "@/assets/icons/drawerIcon/UserManagementIcon.vue";
import OrganizationIcon from "@/assets/icons/drawerIcon/OrganizationIcon.vue";
import LicenseIcon from "@/assets/icons/drawerIcon/LicenseIcon.vue";
import KidIcon from "@/assets/icons/drawerIcon/KidIcon.vue";
import BarGraphIcon from "@/assets/icons/Dashboard/DashboardStatistics/BarGraphIcon.vue";
import TalkIcon from "@/assets/icons/Dashboard/DashboardStatistics/TalkIcon.vue";
import BeforeIcon from "@/assets/icons/Dashboard/DashboardStatistics/BeforeIcon.vue";
import AfterIcon from "@/assets/icons/Dashboard/DashboardStatistics/AfterIcon.vue";
import CalendarIcon from "@/assets/icons/Dashboard/DashboardStatistics/CalendarIcon.vue";
import PrimaryColorKidIcon from "@/assets/icons/PrimaryColorKidIcon.vue";
import TiltingPinIcon from "@/assets/icons/Kid/KidInfo/KidInfoDetails/TiltingPinIcon.vue";
import DiaryIcon from "@/assets/icons/Kid/KidInfo/KidInfoDetails/DiaryIcon.vue";
import SurveyIcon from "@/assets/icons/Kid/KidInfo/KidInfoDetails/SurveyIcon.vue";
import RateIcon from "@/assets/icons/Kid/KidTrend/RateIcon.vue";
import DashboardKidIcon from "@/assets/icons/Dashboard/DashboardStatistics/DashboardKidIcon.vue";
import PinIcon from "@/assets/icons/Kid/PinIcon.vue";
import PersonIcon from "@/assets/icons/Kid/PersonIcon.vue";
import ClockIcon from "@/assets/icons/Kid/ClockIcon.vue";
import MicIcon from "@/assets/icons/Kid/MicIcon.vue";
import CheckBookIcon from "@/assets/icons/CheckBookIcon.vue";
import SorrowIcon from "@/assets/icons/Kid/KidEmotion/SorrowIcon.vue";
import AngryIcon from "@/assets/icons/Kid/KidEmotion/AngryIcon.vue";
import JoyIcon from "@/assets/icons/Kid/KidEmotion/JoyIcon.vue";
import NonsenseIcon from "@/assets/icons/Kid/KidEmotion/NonsenseIcon.vue";
import '@mdi/font/css/materialdesignicons.css'
import AdminIcon from "@/assets/icons/AdminIcon.vue";
import ChartIcon from "@/assets/icons/Kid/KidInfo/KidInfoDetails/ChartIcon.vue";
import LinearGraphIcon from "@/assets/icons/Dashboard/DashboardStatistics/LinearGraphIcon.vue";


Vue.use(Vuetify);

export default new Vuetify({
  lang: {
    locales: {
      en: locale.en,
      ko: locale.ko
    },
    current: "ko"
  },
  theme: {
    themes: {
      light: LIGHT_PALETTE,
      dark: DARK_PALETTE
    }
  },
  icons: {
    iconfont: "mdi",
    values: {
      FormeLogo: {
        component: FormeLogo
      },
      AppPushIcon: {
        component: AppPushIcon
      },
      HomeIcon: {
        component: HomeIcon
      },
      OrganizationIcon: {
        component: OrganizationIcon
      },
      KidIcon: {
        component: KidIcon
      },
      LicenseIcon: {
        component: LicenseIcon
      },
      PromiseIcon: {
        component: PromiseIcon
      },
      UserManagementIcon: {
        component: UserManagementIcon
      },
      BarGraphIcon: {
        component: BarGraphIcon
      },
      TalkIcon: {
        component: TalkIcon
      },
      BeforeIcon: {
        component: BeforeIcon
      },
      AfterIcon: {
        component: AfterIcon
      },
      CalendarIcon: {
        component: CalendarIcon
      },
      PrimaryColorKidIcon: {
        component: PrimaryColorKidIcon
      },
      TiltingPinIcon: {
        component: TiltingPinIcon
      },
      ChartIcon: {
        component: ChartIcon
      },
      DiaryIcon: {
        component: DiaryIcon
      },
      SurveyIcon: {
        component: SurveyIcon
      },
      RateIcon: {
        component: RateIcon
      },
      DashboardKidIcon: {
        component: DashboardKidIcon
      },
      PinIcon: {
        component: PinIcon
      },
      PersonIcon: {
        component: PersonIcon
      },
      ClockIcon: {
        component: ClockIcon
      },
      MicIcon: {
        component: MicIcon
      },
      CheckBookIcon: {
        component: CheckBookIcon
      },
      AngryIcon:{
        component:AngryIcon
      },
      JoyIcon:{
        component:JoyIcon
      },
      NonsenseIcon:{
        component:NonsenseIcon
      },
      SorrowIcon:{
        component:SorrowIcon
      },
      AdminIcon:{
        component:AdminIcon
      },
      LinearGraphIcon:{
        component:LinearGraphIcon
      },
    }
  }
});
