<template>
  <svg fill="none" height="22" viewBox="0 0 22 22" width="22" xmlns="http://www.w3.org/2000/svg">
    <circle cx="11" cy="11" fill="#FC9091" r="11"/>
    <path d="M7.07143 10.2142C7.93931 10.2142 8.64286 9.33481 8.64286 8.24996C8.64286 7.16512 7.93931 7.85711 7.07143 7.85711C6.20355 7.85711 5.5 7.16512 5.5 8.24996C5.5 9.33481 6.20355 10.2142 7.07143 10.2142Z" fill="#514B4B"/>
    <path d="M14.9289 10.2142C15.7967 10.2142 16.5003 9.33481 16.5003 8.24996C16.5003 7.16512 15.7967 7.85711 14.9289 7.85711C14.061 7.85711 13.3574 7.16512 13.3574 8.24996C13.3574 9.33481 14.061 10.2142 14.9289 10.2142Z" fill="#514B4B"/>
    <path d="M14.9186 16.1097C14.9107 16.082 14.1007 13.3572 11.0006 13.3572C7.90043 13.3572 7.0904 16.082 7.08254 16.1097C7.04468 16.2436 7.11397 16.3806 7.24826 16.4516C7.38256 16.522 7.55756 16.5057 7.674 16.4139C7.68043 16.4095 8.3976 15.8715 11.0006 15.8715C13.5649 15.8715 14.2986 16.3932 14.3264 16.4139C14.3943 16.4711 14.4828 16.5 14.5721 16.5C14.6321 16.5 14.6929 16.4868 14.7479 16.4598C14.8879 16.39 14.9579 16.2467 14.9186 16.1097Z" fill="#514B4B"/>
    <path d="M4.71484 7.07141L8.64342 7.85713" stroke="#514B4B" stroke-linecap="round"/>
    <path d="M17.2852 7.07141L13.3566 7.85713" stroke="#514B4B" stroke-linecap="round"/>
  </svg>

</template>

<script>
export default { name: "AngryIcon" };
</script>
