<template>
  <svg fill="none" height="22" viewBox="0 0 22 22" width="22" xmlns="http://www.w3.org/2000/svg">
    <circle cx="11" cy="11" fill="#FFD799" r="11"/>
    <path d="M11.0003 15.7143C12.736 15.7143 14.1431 15.0107 14.1431 14.1428C14.1431 13.275 12.736 12.5714 11.0003 12.5714C9.26453 12.5714 7.85742 13.275 7.85742 14.1428C7.85742 15.0107 9.26453 15.7143 11.0003 15.7143Z" fill="#514B4B"/>
    <path d="M7.07143 9.42857C7.93931 9.42857 8.64286 8.54913 8.64286 7.46429C8.64286 6.37944 7.93931 5.5 7.07143 5.5C6.20355 5.5 5.5 6.37944 5.5 7.46429C5.5 8.54913 6.20355 9.42857 7.07143 9.42857Z" fill="#514B4B"/>
    <path d="M14.9289 9.42857C15.7967 9.42857 16.5003 8.54913 16.5003 7.46429C16.5003 6.37944 15.7967 5.5 14.9289 5.5C14.061 5.5 13.3574 6.37944 13.3574 7.46429C13.3574 8.54913 14.061 9.42857 14.9289 9.42857Z" fill="#514B4B"/>
  </svg>
</template>

<script>
export default { name: "NonsenseIcon" };
</script>
