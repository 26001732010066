import { ActionTree } from "vuex";
import {
  AlertDialogInterface,
  ConfirmDialogInterface,
   RootState,
  state,
} from "@/store/state";
import { MutationTypes } from "@/store/mutations";
import { KidInterface } from "@/views/KidList/Kid.vue";


enum ActionTypes {
  LOCAL_SET = "LOCAL_SET",
  DRAWER_SET = "DRAWER_SET",
  DARK_THEME_SET = "DARK_THEME_SET",
  TIMELINE_SET = "TIMELINE_SET",
  CALENDAR_SET = "CALENDAR_SET",
  DEVOPS_SET = "DEVOPS_SET",
  ALERT_DIALOG_SET = "ALERT_DIALOG_SET",
  CONFIRM_DIALOG_SET = "CONFIRM_DIALOG_SET",
  RECENT_KID_SET="RECENT_KID_SET"
}

const actions: ActionTree<typeof state, RootState> = {
  [ActionTypes.LOCAL_SET]: ({ commit }, payload: string): void => {
    commit(MutationTypes.SETTING_SET, { locale: payload });
  },

  [ActionTypes.DRAWER_SET]: ({ commit }, payload: boolean): void => {
    commit(MutationTypes.SETTING_SET, { drawer: payload });
  },

  [ActionTypes.DARK_THEME_SET]: ({ commit }, payload: boolean): void => {
    commit(MutationTypes.SETTING_SET, { darkTheme: payload });
  },

  [ActionTypes.TIMELINE_SET]: ({ commit }, payload: boolean): void => {
    commit(MutationTypes.SETTING_SET, { timeline: payload });
  },

  [ActionTypes.CALENDAR_SET]: ({ commit }, payload: boolean): void => {
    commit(MutationTypes.SETTING_SET, { calendar: payload });
  },

  [ActionTypes.DEVOPS_SET]: ({ commit }, payload: boolean): void => {
    commit(MutationTypes.SETTING_SET, { devops: payload });
  },


  [ActionTypes.ALERT_DIALOG_SET]: async (
    { commit },
    payload: AlertDialogInterface
  ): Promise<void> => {
    commit(MutationTypes.ALERT_DIALOG_SET, payload);
  },

  [ActionTypes.CONFIRM_DIALOG_SET]: (
    { commit },
    payload: ConfirmDialogInterface
  ): void => {
    commit(MutationTypes.CONFIRM_DIALOG_SET, payload);
  },

  [ActionTypes.RECENT_KID_SET]: (
    { commit },
    payload: KidInterface
  ): void => {
    commit(MutationTypes.RECENT_KID_SET, payload);
  },

};
type Actions = typeof actions;

export { ActionTypes, actions, Actions };
