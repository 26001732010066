import { MutationTree } from "vuex";
import { AlertDialogInterface, ConfirmDialogInterface, RootState, SettingInterface, state } from "@/store/state";
import { AdminResponse, OrganizationResponse } from "@/generated/adminapi";
import { KidInterface } from "@/views/KidList/Kid.vue";


enum MutationTypes {
  SETTING_SET = "SETTING_SET",
  ADMIN_SET = "ADMIN_SET",
  ALERT_DIALOG_SET = "ALERT_DIALOG_SET",
  CONFIRM_DIALOG_SET = "CONFIRM_DIALOG_SET",
  RECENT_KID_SET = "RECENT_KID_SET",
  LOADING_SET = "LOADING_SET",
  ORGANIZATION_LIST_SET = "ORGANIZATION_LIST_SET",
  FIRST_GROUP = "FIRST_GROUP",
}

const mutations: MutationTree<typeof state> = {
  [MutationTypes.SETTING_SET]: (
    state: RootState,
    setting: SettingInterface
  ): void => {
    state.setting = { ...state.setting, ...setting };
  },

  [MutationTypes.ADMIN_SET]: (state: RootState, admin: AdminResponse): void => {
    state.admin = admin;
  },

  [MutationTypes.ALERT_DIALOG_SET]: (
    state: RootState,
    alertDialog: AlertDialogInterface
  ): void => {
    delete state.alertDialog.message;
    state.alertDialog = { ...state.alertDialog, ...alertDialog };
  },

  [MutationTypes.CONFIRM_DIALOG_SET]: (
    state: RootState,
    confirmDialog: ConfirmDialogInterface
  ): void => {
    state.confirmDialog = { ...state.confirmDialog, ...confirmDialog };
  },

  [MutationTypes.RECENT_KID_SET]: (
    state: RootState,
    kid: KidInterface
  ): void => {
    state.recentKid = kid;
  },

  [MutationTypes.LOADING_SET]: (state: RootState, loading: boolean): void => {
    state.loading = loading;
  },

  [MutationTypes.ORGANIZATION_LIST_SET]: (
    state: RootState,
    organizationList: OrganizationResponse[]
  ): void => {
    state.organizationList = organizationList;
  },
  [MutationTypes.FIRST_GROUP]: (
    state: RootState,
    firstGroup: string[]
  ): void => {
    state.firstGroup = firstGroup;
  }
};

export { MutationTypes, mutations };
