<template>
  <svg class="customStrokeIcon" fill="none" height="28" stroke="#808080" viewBox="0 0 28 28" width="28" xmlns="http://www.w3.org/2000/svg">
    <path d="M4 24.1538V21.6923C4 20.3866 4.51868 19.1344 5.44194 18.2112C6.36519 17.2879 7.6174 16.7692 8.92308 16.7692H12M20.0012 24.1538C19.3484 24.1538 18.7223 23.8945 18.2607 23.4329C17.799 22.9713 17.5397 22.3451 17.5397 21.6923C17.5397 21.0395 17.799 20.4134 18.2607 19.9517C18.7223 19.4901 19.3484 19.2308 20.0012 19.2308M20.0012 24.1538C20.6541 24.1538 21.2802 23.8945 21.7418 23.4329C22.2034 22.9713 22.4628 22.3451 22.4628 21.6923C22.4628 21.0395 22.2034 20.4134 21.7418 19.9517C21.2802 19.4901 20.6541 19.2308 20.0012 19.2308M20.0012 24.1538V26M20.0012 19.2308V17.3846M23.7317 19.5385L22.1329 20.4615M17.8708 22.9231L16.2708 23.8462M16.2708 19.5385L17.8708 20.4615M22.1329 22.9231L23.7329 23.8462M6.46154 6.92308C6.46154 8.22876 6.98022 9.48096 7.90347 10.4042C8.82673 11.3275 10.0789 11.8462 11.3846 11.8462C12.6903 11.8462 13.9425 11.3275 14.8658 10.4042C15.789 9.48096 16.3077 8.22876 16.3077 6.92308C16.3077 5.6174 15.789 4.36519 14.8658 3.44194C13.9425 2.51868 12.6903 2 11.3846 2C10.0789 2 8.82673 2.51868 7.90347 3.44194C6.98022 4.36519 6.46154 5.6174 6.46154 6.92308Z"  stroke-linecap="round" stroke-linejoin="round" stroke-width="3"/>
  </svg>

</template>

<script>
export default { name: "UserManagementIcon" };
</script>
