<template>
  <svg fill="none" height="30" viewBox="0 0 36 30" width="36" xmlns="http://www.w3.org/2000/svg">
    <path clip-rule="evenodd" d="M23.9371 22.6966C24.2643 22.1044 24.1262 21.3828 23.6719 20.8904C23.0443 20.2187 22.6463 19.345 22.5391 18.4033C22.4319 17.4617 22.6213 16.5041 23.0784 15.6776L26.5596 9.37821L27.7439 10.0926C28.3953 10.4855 29.2416 10.2401 29.6246 9.54714C30.0075 8.8542 29.7878 7.96577 29.1364 7.57285L17.2926 0.428809C16.6412 0.0358863 15.7949 0.281351 15.412 0.974287C15.029 1.66722 15.2487 2.55565 15.9001 2.94857L17.0845 3.66298L13.6033 9.96239C12.6425 11.701 10.7334 12.4981 8.98556 12.0318C8.35023 11.8669 7.68297 12.1028 7.35574 12.6949L7.26523 12.8587C6.8823 13.5516 7.10197 14.4401 7.75338 14.833L13.6516 18.3907L8.80164 27.2242C8.41871 27.9171 8.63837 28.8056 9.28978 29.1985C9.94119 29.5914 10.7875 29.3459 11.1704 28.653L16.0204 19.8195L21.966 23.4058C22.6174 23.7988 23.4636 23.5533 23.8466 22.8604L23.9371 22.6966Z" fill="#80BC5E" fill-rule="evenodd"/>
  </svg>


</template>

<script>
export default { name: "PinIcon" };
</script>
