import store from "@/store";

type TimestampParamType = number | Date | string | null;
type TimestampParamTypeExceptString = number | Date | null;

interface Clock {
  hours: number;
  minutes: number;
  seconds: number;
}

class DateTimeClass {
  static readonly MMDD: Intl.DateTimeFormatOptions = {
    month: "2-digit",
    day: "2-digit"
  };

  static readonly HHMM: Intl.DateTimeFormatOptions = {
    hour12: false,
    hour: "2-digit",
    minute: "2-digit"
  };

  static readonly HHMMSS: Intl.DateTimeFormatOptions = {
    hour12: false,
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit"
  };

  static timestampToDate(timestamp?: TimestampParamType): Date | null {
    if (timestamp === null || timestamp === undefined || timestamp < 0) {
      return null;
    } else if (timestamp instanceof Date) {
      return timestamp;
    }

    const date = new Date(timestamp);
    return isNaN(date.getTime()) ? null : date;
  }

  static toString(
    timestamp?: TimestampParamTypeExceptString,
    alt?: string
  ): string {
    const date = DateTimeClass.timestampToDate(timestamp);
    return date
      ? date.toLocaleString(store.getters.setting.locale)
      : this.checkAlt(alt);
  }

  static toDateString(
    timestamp?: TimestampParamTypeExceptString,
    alt?: string
  ): string {
    const date = DateTimeClass.timestampToDate(timestamp);
    return date
      ? date.toLocaleDateString(store.getters.setting.locale)
      : this.checkAlt(alt);
  }

  static toMMDD(timestamp?: TimestampParamType, alt?: string): string {
    const date = DateTimeClass.timestampToDate(timestamp);
    return date
      ? date.toLocaleDateString(
        store.getters.setting.locale,
        DateTimeClass.MMDD
      )
      : this.checkAlt(alt);
  }

  static toTimeString(
    timestamp?: TimestampParamTypeExceptString,
    alt?: string
  ): string {
    const date = DateTimeClass.timestampToDate(timestamp);
    return date
      ? date.toLocaleTimeString(store.getters.setting.locale)
      : this.checkAlt(alt);
  }

  static toHHMM(
    timestamp?: TimestampParamTypeExceptString,
    alt?: string
  ): string {
    const date = DateTimeClass.timestampToDate(timestamp);
    return date
      ? date.toLocaleTimeString(
        store.getters.setting.locale,
        DateTimeClass.HHMM
      )
      : this.checkAlt(alt);
  }

  static toHHMMSS(
    timestamp?: TimestampParamTypeExceptString,
    alt?: string
  ): string {
    const date = DateTimeClass.timestampToDate(timestamp);
    return date
      ? date.toLocaleTimeString(
        store.getters.setting.locale,
        DateTimeClass.HHMMSS
      )
      : this.checkAlt(alt);
  }

  static toCalendarDate(timestamp?: TimestampParamType, alt?: string): string {
    const date = DateTimeClass.timestampToDate(timestamp);
    if (date) {
      const year = date.getFullYear();
      const month = date.getMonth() + 1;
      const day = date.getDate();
      return [
        year.toString(),
        month.toString().padStart(2, "0"),
        day.toString().padStart(2, "0")
      ].join("-");
    }
    return this.checkAlt(alt);
  }

  static toCalendarTime(timestamp?: number | Date, alt?: string): string {
    return DateTimeClass.toHHMM(timestamp, alt);
  }

  static toCalendar(timestamp?: number | Date, alt?: string): string {
    const date = DateTimeClass.timestampToDate(timestamp);
    return date
      ? `${DateTimeClass.toCalendarDate(
        timestamp
      )} ${DateTimeClass.toCalendarTime(timestamp)}`
      : this.checkAlt(alt);
  }

  static durationToHHMMSS(duration: number | null): string {
    const clocks = [0, 0, 0]; // hours, minutes, seconds
    if (duration) {
      clocks[2] = duration / 1000;
      clocks[0] = Math.floor(clocks[2] / 3600);
      clocks[2] %= 3600;
      clocks[1] = Math.floor(clocks[2] / 60);
      clocks[2] %= 60;
    }
    return clocks.map((clock) => clock.toString(10).padStart(2, "0")).join(":");
  }

  static hhmmssToClock(hhmmss?: string | null): Clock | null {
    if (hhmmss === null || hhmmss === undefined || hhmmss === "") {
      return null;
    }

    const clock: Clock = {
      hours: 0,
      minutes: 0,
      seconds: 0
    };

    const tokens = hhmmss.split(":");
    switch (tokens.length) {
      case 3:
        clock.seconds = parseInt(tokens[2], 10);
      // fallthrough
      case 2:
        clock.minutes = parseInt(tokens[1], 10);
      // fallthrough
      case 1:
        clock.hours = parseInt(tokens[0], 10);
        if (Object.is(clock.hours, -0)) {
          clock.minutes = -clock.minutes;
        }
        break;
      default:
        throw Error("Invalid time");
    }

    return clock;
  }

  static hhmmssToDate(hhmmss?: string | null): Date | null {
    const clock = DateTimeClass.hhmmssToClock(hhmmss);
    if (!clock) {
      return null;
    }

    const date = new Date();
    date.setSeconds(clock.seconds);
    date.setMinutes(clock.minutes);
    date.setHours(clock.hours);

    return date;
  }

  static hhmmssToTimeString(hhmmss?: string | null, alt?: string): string {
    const date = DateTimeClass.hhmmssToDate(hhmmss);
    return date ? DateTimeClass.toTimeString(date) : this.checkAlt(alt);
  }

  static timeGapToMinutes(timeGap: string): number {
    const clock = DateTimeClass.hhmmssToClock(timeGap);
    return clock ? clock.hours * 60 + clock.minutes : 0;
  }

  static timeGapToMinutesString(timeGap: string): string {
    const minutes = DateTimeClass.timeGapToMinutes(timeGap);
    return minutes > 0 ? `+${minutes}` : minutes.toString();
  }

  static timeGapToTimeString(hhmmss: string, timeGap: string): string {
    const date = DateTimeClass.hhmmssToDate(hhmmss);
    if (!date) {
      return "-";
    }
    date.setMinutes(
      date.getMinutes() + DateTimeClass.timeGapToMinutes(timeGap)
    );
    return DateTimeClass.toTimeString(date);
  }

  static getDateDiff(to: string, from?: string): number {
    const date1 = new Date(to);
    const date2 = from ? new Date(from) : new Date();

    const diffDate = date1.getTime() - date2.getTime();

    return Math.round(diffDate / (1000 * 60 * 60 * 24)); // 밀리세컨 * 초 * 분 * 시 = 일
  }

  static checkAlt(alt: string | undefined) {
    return alt === undefined ? "n/a" : alt;
  }
}

export default DateTimeClass;
