import Vue from "vue";
import colors from "vuetify/lib/util/colors";
import { VuetifyParsedThemeItem } from "vuetify/types/services/theme";
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";
import * as formeAPI from "@/generated/formeapi";
import * as adminAPI from "@/generated/adminapi";
import DateTimeClass from "./datetime";
import UtilClass from "./util";
import { AdminServerClass, APIServerClass } from "./server";
import store from "@/store";
import { typeKo } from "@/plugins/locale/localeTypes";


Vue.use(Toast, {
  transition: "Vue-Toastification__fade",
  timeout: 2000,
  hideProgressBar: true,
  pauseOnFocusLoss: true,
  pauseOnHover: true,
  icon: true
});

export interface HaiiInterface {
  localFlag: boolean;
  name: string;
  datetime: typeof DateTimeClass;
  util: typeof UtilClass;
  apiServer: APIServerClass;
  adminServer: AdminServerClass;
  dictionary: (
    index: string,
    key: typeKo,
    ...params: Array<string | number>
  ) => string;
  text: (key: typeKo, ...params: Array<string | number>) => string;
  bgColor: (color: string, level?: number) => string;
  w3cColor: (color: string, variation?: string) => string;
  w3cBGColor: (color: string, level?: number) => string;
  roleLevelToText: (roleLevel: number) => string;
  roleTextToLevel: (role: string) => number;
  isTeacher: () => boolean;
}

declare module "vue/types/vue" {
  interface Vue {
    $haii: () => HaiiInterface;
  }
}

export { formeAPI, adminAPI };

export const DEFAULT_PALETTE = {
  primary: "#80BC5E",
  secondary: colors.cyan.darken4,
  accent: colors.pink,
  info: colors.blue,
  warning: colors.orange,
  error: colors.red,
  success: colors.green,
  anchor: colors.cyan.darken1,
  grey: colors.grey
};

export const LIGHT_PALETTE = {
  ...DEFAULT_PALETTE
};

export const DARK_PALETTE = {
  ...DEFAULT_PALETTE
};

export const DEVOPS_PALETTE = {
  ...DEFAULT_PALETTE,
  primary: colors.purple.darken1,
  secondary: colors.purple.darken4
};

export default {
  install: (v: typeof Vue): void => {
    v.mixin({
      methods: {
        $haii(): HaiiInterface {
          return {
            localFlag: process.env.VUE_APP_ENV == "localhost",

            name: this.constructor.name,

            datetime: DateTimeClass,
            util: UtilClass,

            apiServer: new APIServerClass(),
            adminServer: new AdminServerClass(),

            dictionary: (
              index: string,
              key: typeKo,
              ...params: Array<string | number>
            ): string => {
              if (typeof key != "string") return "";
              return key
                ? this.$vuetify.lang.t(`$vuetify.${index}.${key}`, ...params)
                : key;
            },

            text: (key: typeKo, ...params: Array<string | number>): string => {
              return this.$haii().dictionary(this.$haii().name, key, ...params);
            },

            bgColor: (color: string, level?: number): string => {
              switch (color) {
                case "white":
                  return this.$store.getters.setting.darkTheme
                    ? "black"
                    : "white";
                case "black":
                  return this.$store.getters.setting.darkTheme
                    ? "white"
                    : "black";
                default:
                  break;
              }

              const colors = [color];
              if (level) {
                if (this.$store.getters.setting.darkTheme) {
                  colors.push(`darken-${level}`);
                } else {
                  colors.push(`lighten-${level}`);
                }
              }

              return colors.join(" ");
            },

            w3cColor: (color: string, variation?: string): string => {
              const item = this.$vuetify.theme.currentTheme[
                color
                ] as VuetifyParsedThemeItem;

              return item[
                (variation || "base") as keyof VuetifyParsedThemeItem
                ];
            },

            w3cBGColor: (color: string, level?: number): string => {
              let variation;
              if (level) {
                if (this.$store.getters.setting.darkTheme) {
                  variation = `darken${level}`;
                } else {
                  variation = `lighten${level}`;
                }
              }

              return this.$haii().w3cColor(color, variation);
            },
            roleLevelToText(roleLevel: number) {
              switch (roleLevel) {
                case 255:
                  return "개발팀";
                case 245:
                  return "기획팀";
                case 200:
                  return "자문 의사";
                case 190:
                  return "교육청";
                case 170:
                  return "대표 관리자";
                case 150:
                  return "교장,교감 선생님";
                case 100:
                  return "담임 선생님";
                case 50:
                  return "위클래스 선생님";
                default:
                  return "존재하는않는 레벨";
              }
            },
            roleTextToLevel(role: string) {
              switch (role) {
                case "개발팀":
                  return 255;
                case "기획팀":
                  return 245;
                case "자문 의사":
                  return 200;
                case "교육청":
                  return 190;
                case "대표 관리자":
                  return 170;
                default:
                  return 0;
              }
            },
            isTeacher() {
              return store.getters.admin.roleLevel == 100;
            }
          };
        }
      }
    });
  }
};
