<template>
  <svg fill="none" height="26" viewBox="0 0 27 26" width="27" xmlns="http://www.w3.org/2000/svg">
    <path clip-rule="evenodd" d="M19.7423 20.0106C20.0383 19.498 19.9134 18.8734 19.5025 18.4472C18.935 17.8658 18.5751 17.1096 18.4782 16.2945C18.3812 15.4795 18.5525 14.6507 18.9658 13.9353L22.1138 8.48286L23.1848 9.10121C23.7739 9.4413 24.5392 9.22884 24.8854 8.62907C25.2317 8.0293 25.0331 7.26032 24.444 6.92023L13.7338 0.736705C13.1448 0.396611 12.3795 0.609073 12.0332 1.20884C11.6869 1.80861 11.8856 2.57759 12.4746 2.91769L13.5457 3.53604L10.3977 8.98849C9.52884 10.4934 7.80247 11.1833 6.22192 10.7796C5.6474 10.637 5.04401 10.8411 4.7481 11.3536L4.66625 11.4954C4.31997 12.0952 4.51861 12.8641 5.10767 13.2042L10.4413 16.2836L6.0556 23.9294C5.70932 24.5292 5.90796 25.2982 6.49702 25.6383C7.08608 25.9784 7.85135 25.7659 8.19763 25.1661L12.5834 17.5203L17.9599 20.6245C18.5489 20.9646 19.3142 20.7521 19.6605 20.1523L19.7423 20.0106Z" fill="white" fill-rule="evenodd"/>
  </svg>

</template>

<script>
export default { name: "TiltingPinIcon" };
</script>
