<template>
  <svg fill="none" height="22" viewBox="0 0 22 22" width="22" xmlns="http://www.w3.org/2000/svg">
    <circle cx="11" cy="11" fill="#D2F0C1" r="11"/>
    <path d="M11 12.3761C9.1885 12.3761 7.9865 12.1358 6.5 11.8066C6.1605 11.732 5.5 11.8066 5.5 12.9457C5.5 15.2238 7.7975 18.0715 11 18.0715C14.202 18.0715 16.5 15.2238 16.5 12.9457C16.5 11.8066 15.8395 11.7314 15.5 11.8066C14.0135 12.1358 12.8115 12.3761 11 12.3761Z" fill="#514B4B"/>
    <path d="M7.07143 9.42857C7.93931 9.42857 8.64286 8.54913 8.64286 7.46429C8.64286 6.37944 7.93931 5.5 7.07143 5.5C6.20355 5.5 5.5 6.37944 5.5 7.46429C5.5 8.54913 6.20355 9.42857 7.07143 9.42857Z" fill="#514B4B"/>
    <path d="M14.9289 9.42857C15.7967 9.42857 16.5003 8.54913 16.5003 7.46429C16.5003 6.37944 15.7967 5.5 14.9289 5.5C14.061 5.5 13.3574 6.37944 13.3574 7.46429C13.3574 8.54913 14.061 9.42857 14.9289 9.42857Z" fill="#514B4B"/>
  </svg>
</template>

<script>
export default { name: "JoyIcon" };
</script>
