<template>
  <svg fill="none" height="24" viewBox="0 0 25 24" width="25" xmlns="http://www.w3.org/2000/svg">
    <path clip-rule="evenodd" d="M7.74197 2.3H17.5197V0H19.9642V2.3H21.1864C21.8347 2.3 22.4565 2.54232 22.9149 2.97365C23.3733 3.40499 23.6309 3.99 23.6309 4.6V20.7C23.6309 21.31 23.3733 21.895 22.9149 22.3263C22.4565 22.7577 21.8347 23 21.1864 23H4.0753C3.427 23 2.80524 22.7577 2.34682 22.3263C1.8884 21.895 1.63086 21.31 1.63086 20.7V4.6C1.63086 3.99 1.8884 3.40499 2.34682 2.97365C2.80524 2.54232 3.427 2.3 4.0753 2.3H5.29753V0H7.74197V2.3ZM4.0753 10.35V20.7H21.1864V10.35H4.0753ZM6.51975 12.075H8.96419V14.375H6.51975V12.075ZM11.4086 12.075H13.8531V14.375H11.4086V12.075ZM16.2975 12.075H18.742V14.375H16.2975V12.075ZM16.2975 16.1H18.742V18.4H16.2975V16.1ZM11.4086 16.1H13.8531V18.4H11.4086V16.1ZM6.51975 16.1H8.96419V18.4H6.51975V16.1Z" fill="#80BC5E" fill-rule="evenodd"/>
  </svg>

</template>

<script>
export default { name: "CalendarIcon" };
</script>
