<template>
  <svg class="customIcon" fill="#808080" height="24" viewBox="0 0 28 24" width="28" xmlns="http://www.w3.org/2000/svg">
    <path clip-rule="evenodd" d="M2 2.4015C2 1.07267 3.07427 0 4.4015 0H23.6135C24.9408 0 26.015 1.07267 26.015 2.4015V16.8009C26.015 18.1281 24.9408 19.2008 23.6135 19.2008H17.6386L14.6735 23.6436C14.6004 23.7532 14.5014 23.8431 14.3852 23.9053C14.269 23.9675 14.1393 24 14.0075 24C13.8757 24 13.746 23.9675 13.6298 23.9053C13.5136 23.8431 13.4146 23.7532 13.3415 23.6436L10.378 19.2008H4.4015C3.07427 19.2008 2 18.1281 2 16.7993V2.4015ZM13.207 14.409V11.207H10.005V9.60601H13.207V6.40401H14.808V9.60601H18.01V11.207H14.808V14.409H13.207Z"  fill-rule="evenodd"/>
  </svg>

</template>

<script>
export default { name: "AppPushIcon" };
</script>
