export type Unpacked<T> = T extends Promise<infer U>
  ? U
  : T extends { data: infer U }
  ? U
  : T;

export type Nullable<T> = { [P in keyof T]: T[P] | null };

export type Obj<T> = { [P in keyof T]: T[P] };

export type Primitive =
  | boolean
  | null
  | undefined
  | number
  | string /* | symbol */;

export type Nested = {
  [key: string]: Primitive | Nested | Array<Primitive | Nested>;
};

export function Clone<T>(source: unknown): T {
  return JSON.parse(JSON.stringify(source));
}
