<template>
  <svg class="customIcon" fill="#808080" height="24" viewBox="0 0 28 24" width="28" xmlns="http://www.w3.org/2000/svg">
    <path d="M10.3231 24C10.8754 24 11.3231 23.5523 11.3231 23V16.5294C11.3231 15.9771 11.7708 15.5294 12.3231 15.5294H15.9846C16.5369 15.5294 16.9846 15.9771 16.9846 16.5294V23C16.9846 23.5523 17.4323 24 17.9846 24H23.0615C23.6138 24 24.0615 23.5523 24.0615 23V13.7059C24.0615 13.1536 24.5093 12.7059 25.0615 12.7059H25.6968C26.6139 12.7059 27.0472 11.5744 26.3648 10.9617L14.8219 0.599679C14.4418 0.258539 13.8658 0.258539 13.4858 0.599679L1.94291 10.9617C1.26045 11.5744 1.69382 12.7059 2.61093 12.7059H3.24615C3.79844 12.7059 4.24615 13.1536 4.24615 13.7059V23C4.24615 23.5523 4.69387 24 5.24615 24H10.3231Z" />
  </svg>

</template>

<script>
export default { name: "HomeIcon" };
</script>
