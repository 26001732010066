/* eslint-disable no-console */

import { register, unregister } from "register-service-worker";

// console.log(process.env.VUE_APP_NAME + " " + process.env.VUE_APP_VERSION)

function forceReset() {
  console.log("service worker refreshing failed, trying to force refreshing");
  caches
    .keys()
    .then((keys) => {
      for (const key of keys) {
        caches.delete(key).then(() => {
          console.log("clearing app cache : " + key);
        });
      }
    })
    .finally(() => {
      unregister();
      window.location.reload();
    });
}

if (process.env.NODE_ENV === "production") {
  register(`${process.env.BASE_URL}service-worker.js`, {
    ready() {
      console.log("Service worker ready.");
    },
    registered() {
      console.log("Service worker has been registered.");
    },
    cached() {
      console.log("Content has been cached for offline use.");
    },
    updatefound() {
      console.log("New content is downloading.");
    },
    updated(registration) {
      try {
        if (registration.waiting) {
          console.log("notify skip waiting to service worker");
          registration.waiting.postMessage({ type: "SKIP_WAITING" });
        } else {
          forceReset();
        }
      } catch (e) {
        forceReset();
      }
    },
    offline() {
      console.log(
        "No internet connection found. App is running in offline mode."
      );
    },
    error(error) {
      console.error("Error during service worker registration:", error);
    },
  });
}

let refreshing = false;
navigator.serviceWorker.addEventListener("controllerchange", () => {
  console.log("service worker controller changed, refreshing");
  if (refreshing) return;
  window.location.reload();
  refreshing = true;
});
