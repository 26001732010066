const RouteNames = {
  MAIN: "MAIN",
  KID_LIST :"KID_LIST",
  KID :"KID",
  KID_HISTORY_LIST :"KID_HISTORY_LIST",
  KID_HELP_FORME_LIST :"KID_HELP_FORME_LIST",
  KID_CHOOSE_PROMISE_LIST:"KID_CHOOSE_PROMISE_LIST",
  KID_DAY_DAIRY_LIST:"KID_DAY_DAIRY_LIST",
  KID_SURVEY_LIST:"KID_SURVEY_LIST",
  KID_HISTORY :"KID_HISTORY",
  PROMISE_HISTORY_LIST :"PROMISE_HISTORY_LIST",
  USER_LIST :"USER_LIST",
  USER_INVITE :"USER_INVITE",
  APP_PUSH :"APP_PUSH",
  LICENSE_MANAGEMENT :"LICENSE_MANAGEMENT",
  ORGANIZATION_LIST :"ORGANIZATION_LIST",
  ORGANIZATION :"ORGANIZATION",
  ORGANIZATION_CHOOSE_PROMISE_LIST:"ORGANIZATION_CHOOSE_PROMISE_LIST",
  ORGANIZATION_DAY_DAIRY_LIST:"ORGANIZATION_DAY_DAIRY_LIST",
  ORGANIZATION_SURVEY_LIST:"ORGANIZATION_SURVEY_LIST",
  ORGANIZATION_HELP_FORME_LIST:"ORGANIZATION_HELP_FORME_LIST",
  ORGANIZATION_HISTORY_LIST :"ORGANIZATION_HISTORY_LIST",
} as const;

export default RouteNames
