import Vuex, { Payload } from "vuex";
import VuexPersistence from "vuex-persist";
import Vue from "vue";
import { RootState, state } from "@/store/state";
import { getters } from "@/store/getters";
import { mutations, MutationTypes } from "@/store/mutations";
import { actions } from "@/store/actions";


const vuexLocal = new VuexPersistence({
  storage: window.localStorage,

  reducer: (state: RootState): Pick<RootState, "setting" > => ({
    setting: state.setting,
   }),

  filter: (mutation: Payload): boolean => {
    switch (mutation.type) {
      case MutationTypes.SETTING_SET:
        return true;
      default:
        break;
    }
    return false;
  },
});

const vuexSession = new VuexPersistence({
  storage: window.sessionStorage,

  reducer: (
    state: RootState
  ): Pick<RootState, "admin" | "organizationList"|"firstGroup"> => ({
    admin: state.admin,
    organizationList: state.organizationList,
    firstGroup:state.firstGroup
  }),

  filter: (mutation: Payload): boolean => {
    switch (mutation.type) {
      case MutationTypes.ADMIN_SET:
        return true;
      case MutationTypes.ORGANIZATION_LIST_SET:
        return true;
      case MutationTypes.FIRST_GROUP:
        return true;

      default:
        break;
    }
    return false;
  },
});

Vue.use(Vuex);

export default new Vuex.Store({
  plugins: [vuexLocal.plugin, vuexSession.plugin],

  state: state,

  getters: getters,

  mutations: mutations,

  actions: actions,

  modules: {},
});
